import { IsEmail, IsOptional, IsString } from 'class-validator'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class UpdateInviteEmailDTO {
  @IsEmail()
  email!: string

  @IsOptional()
  @IsString()
  message?: string
}

export type UpdateInviteDTORes = {
  success: boolean
  email: string
}

export const UPDATE_INVITE_EMAIL_SUB_PATH = 'invites/:supplierId'
export const UPDATE_INVITE_EMAIL_PATH = `${SUPPLIERS_CONTROLLER}/${UPDATE_INVITE_EMAIL_SUB_PATH}`
