import {
  ArrayMaxSize,
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsDateString,
  IsNumber,
  IsOptional,
  IsString,
  Min,
} from 'class-validator'

import { AUDIT_LOG_CONTROLLER_PATH } from './controller.audit-log.vars'

export const AUDIT_LOG_GET_SUBPATH = ''
export const AUDIT_LOG_GET_URI = `${AUDIT_LOG_CONTROLLER_PATH}/${AUDIT_LOG_GET_SUBPATH}`

export class AuditLogsGetDTOReq {
  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  events!: string[]

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  users!: string[]

  @IsArray()
  @ArrayMinSize(2)
  @ArrayMaxSize(2)
  @IsDateString({ strict: true, strictSeparator: true }, { each: true })
  datePeriod!: [string, string] // Array with 2 items [fromDate, toDate]

  @IsBoolean()
  showOnlyFailed!: boolean

  @IsString()
  searchTerm!: string

  @IsNumber()
  @Min(1)
  currentPage!: number

  @IsNumber()
  pageSize!: number
}
export type AuditLog = {
  event: string
  auditCode: string
  level: 'LOW' | 'MEDIUM' | 'HIGH'
  userEmail: string
  date: string
  dateFromNow: string
  outcome: 'SUCCESS' | 'FAILURE'
  effectiveUserPermission: string
  userRole: string
  ipAddress: string
  traceId: string
  orgName: string
  orgId: string
  rootOrgId: string
  rootOrgName: string
  executionTime: number
  method: string
  path: string
  enrichedMetadata: Record<string, unknown>
}

export type AuditLogsGetDTORes = {
  logs: AuditLog[]
  totalRecords: number
}
