import { IsEnum, IsOptional } from 'class-validator'

import { IsYearBeforeOrEqual } from '../decorators/is-year-before-or-equal.dto.decorator'
import { REQUEST_TYPES } from '../../../@enums/data-requests.enums'

import { SUPPLIER_DATA_REQUESTS_CONTROLLER } from './supplier-data-requests.common-vars'

export class GetSupplierDataRequestsProgressDTO {
  @IsYearBeforeOrEqual()
  year!: number

  @IsOptional()
  @IsEnum(REQUEST_TYPES)
  type?: REQUEST_TYPES
}

export const GET_SUPPLIER_DATA_REQUESTS_PROGRESS_SUB_PATH = 'progress'
export const GET_SUPPLIER_DATA_REQUESTS_PROGRESS_PATH = `${SUPPLIER_DATA_REQUESTS_CONTROLLER}/${GET_SUPPLIER_DATA_REQUESTS_PROGRESS_SUB_PATH}`
