import { IsNumber, IsEmail, IsOptional } from 'class-validator'

import type { SupplierPotentialMatch } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierPotentialMatchesDTO {
  @IsEmail()
  email!: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSupplierPotentialMatchesDTORes = {
  potentialMatches: SupplierPotentialMatch[]
  meta: {
    pageNo: number
    perPage: number
    totalCount: number
  }
}

export const GET_SUPPLIER_POTENTIAL_MATCHES_SUB_PATH = 'potential-matches/email'
export const GET_SUPPLIER_POTENTIAL_MATCHES_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_POTENTIAL_MATCHES_SUB_PATH}`
