// eslint-disable-next-line max-classes-per-file
import { IsArray, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_SUPPLIERS_SORT_FIELDS, GET_SUPPLIERS_STATUSES } from '../../../@enums/suppliers.enums'

import type { CorporateRequest } from '../../../@types/suppliers.types'

import { SUPPLIER_CORPORATE_REQUESTS_CONTROLLER } from './supplier-corporate-requests.common-vars'

export class GetSupplierCorporateRequestsDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsEnum(GET_SUPPLIERS_SORT_FIELDS)
  sortField?: GET_SUPPLIERS_SORT_FIELDS

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsOptional()
  @IsString()
  query?: string

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  activities?: string[]

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsEnum(GET_SUPPLIERS_STATUSES, { each: true })
  @IsArray()
  @IsOptional()
  statuses?: GET_SUPPLIERS_STATUSES[]
}

export type GetSupplierCorporateRequestsDTORes = {
  suppliers: CorporateRequest[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIER_CORPORATE_REQUESTS_SUB_PATH = ''
export const GET_SUPPLIER_CORPORATE_REQUESTS_PATH = `${SUPPLIER_CORPORATE_REQUESTS_CONTROLLER}/${GET_SUPPLIER_CORPORATE_REQUESTS_SUB_PATH}`
