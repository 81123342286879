import { defineStore } from 'pinia'

import { sum, range } from 'lodash-es'

import moment from 'moment'

import { showToast } from '@lib/plugins/Toastie'
import FeatureFlagService from '@lib/services/featureFlagService'

import type { GetFranchisesMeasurementProgressDTORes } from '@lib/DTO/franchises/measurement-progress.dto'

import type { ActivitiesScopesTableData } from '@/imports/@types/corporateFootprints/CorporateActivity'

import type {
  // CorporateFootprintActivityBreakdownFacility,
  CorporateFootprintRow,
  CorporateFootprintsActivityDatasources,
  CorporateFootprintsTableData,
  OrgCompletedYearStatuses,
  // ToggleNotApplicable,
  ActivityCompleteness,
  EmissionStats,
} from '@/imports/@types/corporateFootprints/CorporateFootprint'

import { Api } from '@/imports/lib/services/api.service'
import { getApiErrorMessage } from '@/imports/lib/utilities/getApiErrorMessage'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import { useReportsStore } from '@/client/store/reports.pinia'

type State = {
  corporateFootprintsTable: CorporateFootprintsTableData
  activitiesScopesTable: ActivitiesScopesTableData
  isActivitiesScopesTableLoading: boolean
  corporateFootprintsActivityDatasources: CorporateFootprintsActivityDatasources
  dataSourceCount: number
  isFacilityBased: boolean
  orgCompleteYearStatus: OrgCompletedYearStatuses
  isLoadingActivityCompleteness: boolean
  isLoadingEmissionsStats: boolean
  isLoadingFranchiseData: boolean
  activityCompleteness: ActivityCompleteness
  emissionStats: EmissionStats | null
  isSubmittingToggleYearAsComplete: boolean
  completedYears: number[]
  franchiseData: GetFranchisesMeasurementProgressDTORes
}

export const useCorporateFootprintsStore = defineStore('corporateFootprints', {
  state: (): State => ({
    corporateFootprintsTable: {
      rows: [],
      isLoading: true,
    },
    activitiesScopesTable: {
      rows: [],
    },
    isActivitiesScopesTableLoading: false,
    corporateFootprintsActivityDatasources: {
      rows: [],
      isLoading: true,
    },
    dataSourceCount: 0,
    isFacilityBased: false,
    orgCompleteYearStatus: [],

    isLoadingActivityCompleteness: false,
    isLoadingEmissionsStats: false,
    isLoadingFranchiseData: false,

    activityCompleteness: {
      totalActivities: 0,
      completedActivities: 0,
    },
    emissionStats: null,
    isSubmittingToggleYearAsComplete: false,
    completedYears: [],
    franchiseData: {
      totalEmissions: 0,
      totalFranchises: 0,
      totalIngestedFranchises: 0,
    },
  }),

  getters: {
    // check if we have activity's datasources with issues or action required
    hasIssuesInDatasources(): boolean {
      return this.corporateFootprintsActivityDatasources.rows.some(
        datasource => datasource.status === 'action_required' || datasource.status === 'error',
      )
    },

    isDateInCompletedYear(): (date: string) => boolean {
      return (date: string) => {
        if (!this.completedYears.length) return false

        const orgStore = useOrganizationStore()
        const momentDate = moment(date)

        const datesInFinancialYearContext = this.completedYears.map(year => ({
          startDate: orgStore.financialYearFirstDate(year),
          endDate: orgStore.financialYearFirstDate(year).add(1, 'year'),
        }))

        return !!datesInFinancialYearContext.find(year => {
          return momentDate.isSameOrAfter(year.startDate) && momentDate.isSameOrBefore(year.endDate)
        })
      }
    },
  },

  actions: {
    async getYearlyCompletion() {
      this.corporateFootprintsTable.isLoading = true

      const orgStore = useOrganizationStore()
      const orgId = String(orgStore.activeOrganization?.id)

      const reportsStore = useReportsStore()
      const reports = reportsStore.getReports
      const activities = reportsStore.getActivityReports

      try {
        const {
          data: { result },
        } = await Api.corporateFootprints.getRootOrgCompleteYearStatus({
          orgId,
        })

        const today = moment().toDate()
        const baselineYear = orgStore.baselineYear || today.getFullYear()
        const nextYear = today.getFullYear() + 1
        const nextDate = orgStore.financialYearFirstDate(nextYear)
        const diffInMonths = moment(nextDate).diff(today, 'months', true)

        const years = range(baselineYear, nextYear + 1)

        this.completedYears = result.filter(year => year.isComplete).map(({ year }) => +year)

        if (Math.abs(diffInMonths) <= 3) years.push(nextYear)
        const rows = years.map(rowYear => {
          const yearCompletion = result.find(({ year }) => +year === +rowYear)

          const isComplete = yearCompletion?.isComplete || false
          const ghgData = reports[rowYear] || []
          const activitiesData = activities[rowYear] || []
          const totalImpact = ghgData?.emissionsPerScope ? sum(ghgData?.emissionsPerScope) : 0

          const yearFootprint: CorporateFootprintRow = {
            name: `Footprint year ${rowYear}`,
            ghgData,
            activitiesData,
            totalImpact,
            isComplete,
            year: rowYear,
          }

          return yearFootprint
        })

        this.corporateFootprintsTable.rows = rows
      } catch (err) {
        console.error(err)
        showToast({
          type: 'is-danger',
          message: 'There was a problem retrieving yearly progresses for footprints. Please try again later.',
        })
      }

      this.corporateFootprintsTable.isLoading = false
    },

    async getActivitiesWithScope({ orgId, year }: { orgId: string; year: number }) {
      this.isActivitiesScopesTableLoading = true

      try {
        const {
          data: { activities },
        } = await Api.corporateFootprints.getActivitiesWithScope({
          orgId,
          year,
        })
        this.activitiesScopesTable.rows = activities
      } catch (err) {
        console.error(err)
        showToast({
          type: 'is-danger',
          message: `There was a problem retrieving activities scope for ${year}. Please try again later.`,
        })
      }
      this.isActivitiesScopesTableLoading = false
    },

    async toggleActivityScope({
      orgId,
      year,
      activityId,
      subActivityId,
    }: {
      orgId: string
      year: number
      activityId: string
      subActivityId: string
    }) {
      try {
        await Api.corporateFootprints.toggleActivityScope({
          orgId,
          year,
          activityId,
          subActivityId,
        })

        this.clearMemoizedCache()
      } catch (e) {
        console.error(e)
        showToast({
          type: 'is-danger',
          message: `There was a problem changing activities scope for ${year}. Please try again later.`,
        })
      }
    },

    async getActivityDatasources({
      orgId,
      year,
      subActivityId,
    }: {
      orgId: string
      year: number
      subActivityId: string
    }) {
      this.corporateFootprintsActivityDatasources.isLoading = true

      try {
        const {
          data: { dataSources },
        } = await Api.corporateFootprints.getActivityDatasources({
          orgId,
          year,
          subActivityId,
        })

        this.dataSourceCount = dataSources?.length
        this.corporateFootprintsActivityDatasources.rows = dataSources
      } catch (err) {
        console.error(err)
        showToast({
          type: 'is-danger',
          message: "There was a problem retrieving datasources for footprint's activity. Please try again later.",
        })
      }

      this.corporateFootprintsActivityDatasources.isLoading = false
    },

    async getRootOrgCompleteYearStatus({ orgId }: { orgId: string }) {
      try {
        const {
          data: { result },
        } = await Api.corporateFootprints.getRootOrgCompleteYearStatus({
          orgId,
        })

        this.orgCompleteYearStatus = result
      } catch (e) {
        console.error(e)

        showToast({
          type: 'is-danger',
          message: "There was a problem getting the completed status' for org. Please try again later.",
        })
      }
    },

    async toggleYearAsComplete({ orgId, year }: { orgId: string; year: number }) {
      try {
        this.clearMemoizedCache()

        this.isSubmittingToggleYearAsComplete = true

        await Api.corporateFootprints.toggleYearAsComplete({
          orgId,
          year,
        })

        await this.getYearlyCompletion()

        this.isSubmittingToggleYearAsComplete = false
      } catch (e) {
        console.error(e)
        this.isSubmittingToggleYearAsComplete = false

        showToast({
          type: 'is-danger',
          message: 'There was a problem updating the footprint completion. Please try again later.',
        })
      }
    },

    async loadActivityCompleteness({ orgId, year }: { orgId: string; year: number }) {
      this.isLoadingActivityCompleteness = true

      if (FeatureFlagService.isEnabled('rollout-franchise-engagement-v2')) {
        await this.loadFranchiseData(year)
      }

      const {
        data: { status },
      } = await Api.corporateFootprints.getFootprintCompleteness(orgId, year)

      this.activityCompleteness = status
      this.isLoadingActivityCompleteness = false
    },

    async loadEmissionStats({ orgId, year }: { orgId: string; year: number }) {
      this.isLoadingEmissionsStats = true

      try {
        const { data } = await Api.corporateFootprints.getFootprintEmissions(orgId, year)

        this.emissionStats = this.mapEmissionsResponseToPropType(data.emissions)
      } catch (err) {
        if (err instanceof Error) {
          showToast({
            type: 'is-danger',
            message: err.message,
          })
        }
      }

      this.isLoadingEmissionsStats = false
    },

    mapEmissionsResponseToPropType(
      response: Awaited<ReturnType<typeof Api.corporateFootprints.getFootprintEmissions>>['data']['emissions'],
    ): EmissionStats {
      return {
        activityBased: {
          currentValue: response.activity.current,
          previousValue: response.activity.previous,
        },
        spendBased: {
          currentValue: response.spend.current,
          previousValue: response.spend.previous,
        },
        estimated: {
          currentValue: response.estimated.current,
          previousValue: response.estimated.previous,
        },
      }
    },

    async loadFranchiseData(year: number) {
      try {
        this.isLoadingFranchiseData = true

        const { data } = await Api.franchises.getMeasurementProgressDetails(year)

        this.franchiseData = data
      } catch (err) {
        const message = getApiErrorMessage(err)

        showToast({
          type: 'is-danger',
          message,
        })
      } finally {
        this.isLoadingFranchiseData = false
      }
    },

    clearMemoizedCache() {
      Api.corporateFootprints.getFootprintsYearlyBreakdown.clear()
      Api.corporateFootprints.getActivityBreakdown.clear()
      Api.corporateFootprints.getRootOrgCompleteYearStatus.clear()
    },

    clearMemoizedAggregates() {
      Api.corporateFootprints.getFootprintEmissions.clear()
      Api.corporateFootprints.getFootprintCompleteness.clear()
    },
  },
})
