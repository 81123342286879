import type { AxiosInstance } from 'axios'
import { replaceUriParams } from '@lib/DTO'

import {
  GET_SUPPLIERS_PATH,
  type GetSuppliersDTO,
  type GetSuppliersDTORes,
} from '@lib/DTO/suppliersv2/get-suppliers.dto'
import {
  ACCEPT_SUPPLIER_INVITE_PATH,
  type AcceptInviteDTO,
  type AcceptSupplierInviteDTORes,
} from '@lib/DTO/suppliersv2/accept-invite.dto'
import { GET_SUPPLIER_INVITE_PATH, type GetSupplierInviteDTORes } from '@lib/DTO/suppliersv2/get-supplier-invite.dto'
import { RESEND_SUPPLIER_INVITE_PATH, type ResendInviteDTORes } from '@lib/DTO/suppliersv2/resend-invite.dto'
import {
  UPDATE_INVITE_EMAIL_PATH,
  type UpdateInviteEmailDTO,
  type UpdateInviteDTORes,
} from '@lib/DTO/suppliersv2/update-invite-email.dto'
import {
  UPDATE_SUPPLIER_EMAILS_PATH,
  type UpdateSupplierEmailsDTO,
  type UpdateSupplierEmailsDTORes,
} from '@lib/DTO/suppliersv2/update-supplier-emails.dto'
import { GET_SUPPLIER_PATH, type GetSupplierDTO, type GetSupplierDTORes } from '@lib/DTO/suppliersv2/get-supplier.dto'
import {
  EDIT_SUPPLIER_PATH,
  type EditSupplierDTO,
  type EditSupplierDTORes,
} from '@lib/DTO/suppliersv2/edit-supplier-associations.dto'
import {
  MERGE_SUPPLIERS_PATH,
  type MergeSuppliersDTO,
  type MergeSuppliersDTORes,
} from '@lib/DTO/suppliersv2/merge-suppliers.dto'
import {
  GET_SUPPLIER_ASSOCIATIONS_PATH,
  type GetSupplierAssociationsDTO,
  type GetSupplierAssociationsDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-associations.dto'
import { DetachSupplierDto, DETACH_SUPPLIERS_PATH } from '@lib/DTO/suppliersv2/detach-supplier.dto'
import {
  GET_SUPPLIER_ASSOCIATED_PURCHASES_PATH,
  type GetSupplierAssociatedPurchasesDTO,
  type GetSupplierAssociatedPurchasesDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-associated-purchases.dto'

import {
  GET_SUPPLIER_PURCHASES_PATH,
  type GetSupplierPurchasesDTORes,
  GetSupplierPurchasesDTO,
} from '@lib/DTO/suppliersv2/get-supplier-purchases.dto'

import { GET_ORGANISATION_PURCHASES_PATH } from '@lib/DTO/purchases/get-organisation-purchases.dto'

import {
  AddSupplierProductRequestDTO,
  AddSupplierProductRequestAndInviteDTO,
  AddSupplierProductRequestAndAssignDTO,
  type AddSupplierKnownProductRequestDTORes,
  ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_PATH,
  ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_PATH,
  ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_PATH,
} from '@lib/DTO/supplier-product-requests/add-product-request.dto'

import {
  ADD_SUPPLIER_CORPORATE_REQUESTS_PATH,
  type AddSupplierCorporateRequestsDto,
} from '@lib/DTO/supplier-corporate-requests/add-supplier-corporate-requests.dto'

import {
  GET_SUPPLIER_CORPORATE_REQUESTS_PATH,
  type GetSupplierCorporateRequestsDTO,
  type GetSupplierCorporateRequestsDTORes,
} from '@lib/DTO/supplier-corporate-requests/get-supplier-corporate-requests.dto'

import {
  GET_SUPPLIER_POTENTIAL_MATCHES_PATH,
  type GetSupplierPotentialMatchesDTO,
  type GetSupplierPotentialMatchesDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-potential-matches.dto'

import {
  GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_PATH,
  type GetSupplierPotentialMatchesByNameDTO,
  type GetSupplierPotentialMatchesByNameDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-potential-matches-by-name.dto'

import {
  type GetUnrequestedSuppliersDto,
  type GetUnrequestedSuppliersDtoRes,
  GET_UNREQUESTED_SUPPLIERS_PATH,
} from '@lib/DTO/suppliersv2/get-unrequested-suppliers.dto'

import type {
  GetAnnualRespondedDataRequestsPayloadV2,
  GetAnnualRespondedDataRequestsResponse,
} from '@/imports/@types/DataRequest'

import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useSuppliersApi = (http: AxiosInstance) => ({
  getSuppliers(params: GetSuppliersDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSuppliersDTORes>(GET_SUPPLIERS_PATH, { params, headers: { 'x-org-id': activeOrgId } })
  },

  getUnrequestedSuppliers(params: GetUnrequestedSuppliersDto) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetUnrequestedSuppliersDtoRes>(GET_UNREQUESTED_SUPPLIERS_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getSupplier(supplierId: string, params: GetSupplierDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierDTORes>(replaceUriParams(GET_SUPPLIER_PATH, { supplierId }), {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  acceptInvite(payload: AcceptInviteDTO) {
    const { token, user, organization } = payload

    return http.post<AcceptSupplierInviteDTORes>(replaceUriParams(ACCEPT_SUPPLIER_INVITE_PATH, { token }), {
      user,
      organization,
    })
  },

  getInvite(token: string) {
    return http.get<GetSupplierInviteDTORes>(replaceUriParams(GET_SUPPLIER_INVITE_PATH, { token }))
  },

  resendInvite(inviteId: string) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<ResendInviteDTORes>(replaceUriParams(RESEND_SUPPLIER_INVITE_PATH, { inviteId }), undefined, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  updateInviteEmail(supplierId: string, payload: UpdateInviteEmailDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.patch<UpdateInviteDTORes>(replaceUriParams(UPDATE_INVITE_EMAIL_PATH, { supplierId }), payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  updateSupplierEmails(payload: UpdateSupplierEmailsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.patch<UpdateSupplierEmailsDTORes>(UPDATE_SUPPLIER_EMAILS_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getAssociations(supplierId: string, params: GetSupplierAssociationsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierAssociationsDTORes>(replaceUriParams(GET_SUPPLIER_ASSOCIATIONS_PATH, { supplierId }), {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  editSupplier(supplierId: string, payload: EditSupplierDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.patch<EditSupplierDTORes>(replaceUriParams(EDIT_SUPPLIER_PATH, { supplierId }), payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  mergeSuppliers(payload: MergeSuppliersDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<MergeSuppliersDTORes>(MERGE_SUPPLIERS_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getPurchases(supplierId: string, params: GetSupplierPurchasesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    const path = replaceUriParams(GET_SUPPLIER_PURCHASES_PATH, { supplierId })

    return http.get<GetSupplierPurchasesDTORes>(path, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getOrgPurchases(params: GetSupplierPurchasesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierPurchasesDTORes>(GET_ORGANISATION_PURCHASES_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  detachSuppliers(payload: DetachSupplierDto, supplierId: string) {
    const activeOrgId = useOrganizationStore().id || ''
    const path = replaceUriParams(DETACH_SUPPLIERS_PATH, { supplierId })

    return http.patch(path, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getAssociatedPurchases(params: GetSupplierAssociatedPurchasesDTO, supplierId: string) {
    const activeOrgId = useOrganizationStore().id || ''
    const path = replaceUriParams(GET_SUPPLIER_ASSOCIATED_PURCHASES_PATH, { supplierId })

    return http.get<GetSupplierAssociatedPurchasesDTORes>(path, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  sendRequestKnownOrg(payload: AddSupplierProductRequestDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<AddSupplierKnownProductRequestDTORes>(ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  sendRequestAndInvite(payload: AddSupplierProductRequestAndInviteDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<AddSupplierKnownProductRequestDTORes>(ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  sendRequestAndAssign(payload: AddSupplierProductRequestAndAssignDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<AddSupplierKnownProductRequestDTORes>(ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  addSupplierCorporateRequests(payload: AddSupplierCorporateRequestsDto) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post(ADD_SUPPLIER_CORPORATE_REQUESTS_PATH, payload, {
      headers: {
        'x-org-id': activeOrgId,
      },
    })
  },

  getSupplierCorporateRequests(params: GetSupplierCorporateRequestsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierCorporateRequestsDTORes>(GET_SUPPLIER_CORPORATE_REQUESTS_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getAnnualFootprintReport(params: GetAnnualRespondedDataRequestsPayloadV2) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetAnnualRespondedDataRequestsResponse>('/supplier-footprints/annual-report/v2', {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierPotentialMatches(params: GetSupplierPotentialMatchesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierPotentialMatchesDTORes>(GET_SUPPLIER_POTENTIAL_MATCHES_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierPotentialMatchesByName(params: GetSupplierPotentialMatchesByNameDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierPotentialMatchesByNameDTORes>(GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },
})
