import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { AUTH_MODES } from '@/imports/@types/AuthModes'
import type {
  BusinessUnit,
  ReturnValueForAllFacilitiesAndBu,
} from '@/imports/@types/organizationStructure/OrganizationStructure'
import type { Customer } from '@/imports/@types/Customer'
import type {
  Organization,
  OrganizationMetrics,
  OrgLegalEntity,
  RequestAccessAdminDetails,
  RequestAccessOrgDetails,
} from '@/imports/@types/Organization'
import type { DataSourceUploadType } from '@/imports/@types/DataSource'
import type { Report } from '@/imports/@types/Report'
import type { User } from '@/imports/@types/User'
import type { AvailableRole } from '@/imports/@types/Roles'

export type DisableTwoFactorPayload = {
  orgId: string
  totpToken: string
}

export type EnableTwoFactorPayload = {
  orgId: string
  secret: string
  totpToken: string
}

export type FetchCustomerDataPayload = {
  orgId: string
  year: number
  month?: number
  metaQuery: {
    pageNo: number
    perPage: number
    sortField?: string
    sortOrder?: 'asc' | 'desc'
    name?: string
    countryName?: string
  }
}

export type GetCustomerPayload = {
  orgId: string
  customerId: string
}

export type FetchCustomerMonthlyDataPayload = {
  orgId: string
  customerId: string
  year: number
  month?: number
}

export type RequestAccessDetailsResponse = {
  org: RequestAccessOrgDetails
  admins: RequestAccessAdminDetails[]
}

export const useOrganizationApi = (http: AxiosInstance) => ({
  disableTwoFactor(payload: DisableTwoFactorPayload) {
    return http.post<ApiResponseInner<undefined>>('/twofactorauth/disablefororg', payload)
  },

  enableTwoFactor(payload: EnableTwoFactorPayload) {
    type EnableTwoFactorResponse = {
      twoFactorBackupCode: string
    }

    return http.post<ApiResponseInner<EnableTwoFactorResponse>>('/twofactorauth/enablefororg', payload)
  },

  fetchCustomerData(payload: FetchCustomerDataPayload) {
    type FetchCustomerDataResponse = {
      count: number
      rows: Customer[]
    }

    return http.get<ApiResponseInner<FetchCustomerDataResponse>>('/customers/getfororganization', {
      params: {
        orgId: payload.orgId,
        year: payload.year,
        month: payload.month,
        timePeriod: payload.month ? 'month' : 'year',
        ...payload.metaQuery,
      },
    })
  },

  fetchCustomerMonthlyData(payload: FetchCustomerMonthlyDataPayload) {
    return http.get<ApiResponseInner<Customer[]>>('/customers/getbymonthforyear', {
      params: payload,
    })
  },

  getCustomer(payload: GetCustomerPayload) {
    return http.get<ApiResponseInner<{ data: Record<number, Customer>; reports: Report[] }>>('/customers/get', {
      params: payload,
    })
  },

  get(orgId: string) {
    return http.get<ApiResponseInner<Organization>>('/organization/get', {
      params: {
        orgId,
      },
    })
  },

  getTotalStats(orgId: string) {
    return http.get<ApiResponseInner<OrganizationMetrics>>('/organization/gettotalstats', {
      params: {
        orgId,
      },
    })
  },

  getUsers(orgId: string, include2FA?: boolean) {
    return http.get<ApiResponseInner<User[]>>('/organization/getusers', { params: { orgId, include2FA } })
  },

  listForUser({ rootOnly }: { rootOnly: boolean }) {
    return http.get<ApiResponseInner<Organization[]>>('/organization/listforuser', { params: { rootOnly } })
  },

  getByName(orgName: string) {
    return http.get<ApiResponseInner<Organization>>('/organization/getbyname', {
      params: {
        orgName,
      },
    })
  },

  getById(orgId: string) {
    return http.get<ApiResponseInner<Organization>>('/organization/getbyid', { params: { orgId } })
  },

  getEditDetails(orgId: string) {
    return http.get<ApiResponseInner<Organization>>('/organization/geteditdetails', { params: { orgId } })
  },

  getAllChildOrgsAndFacilities(orgId: string) {
    return http.get<ApiResponseInner<BusinessUnit[]>>('organizationStructure/getallchildorgsandfacilities', {
      params: {
        orgId,
      },
    })
  },

  getAllOrgsAndFacilities(orgId: string, rootOrgId: string) {
    return http.get<ApiResponseInner<ReturnValueForAllFacilitiesAndBu>>(
      'organizationStructure/getallorgsandfacilities',
      {
        params: {
          orgId,
          rootOrgId,
        },
      },
    )
  },

  updateProductUploadType({
    orgId,
    uploadType,
  }: {
    orgId?: string // if we don't pass an orgId the root org will be used automatically
    uploadType: DataSourceUploadType
  }) {
    return http.patch<ApiResponseInner<Organization['configuration']>>('/organization/updateproductuploadtype', {
      orgId,
      uploadType,
    })
  },

  updateSettings(orgId: string, updates: Partial<Organization['configuration']>) {
    return http.patch<{ success: boolean }>('/organization/updatesettings', { orgId, settings: updates })
  },

  updateDetails(orgId: string, updates: Partial<Organization>) {
    return http.patch<{ success: boolean }>('/organization/updatedetails', { orgId, organizationDetails: updates })
  },

  updateScope(orgId: string, updates: Partial<Organization['configuration']>) {
    return http.patch<{ success: boolean }>('/organization/updateorganizationscope', {
      orgId,
      organizationConfiguration: updates,
    })
  },

  updateAsanaDetails(orgId: string, updates: { asanaCompanyId: string; asanaOwnerId: string }) {
    return http.patch<{ success: boolean }>('/organization/updateasanasettings', {
      orgId,
      asanaCompanyId: updates.asanaCompanyId,
      asanaOwnerId: updates.asanaOwnerId,
    })
  },

  updateAuthenticationSettings(
    orgId: string,
    updates: {
      mode: AUTH_MODES
      samlCertificate: string
      samlEndpointUrl: string
      samlIdentityProvider: string
      isInvitingExternalUsersEnabled?: boolean
      isSSOEntitlementControlledViaAQ?: boolean
    },
  ) {
    return http.patch<{ success: boolean }>('/organization/updateauthentication', {
      orgId,
      authentication: {
        ...updates,
      },
    })
  },

  getAvailableRoles(orgId: string) {
    return http.get<ApiResponseInner<AvailableRole>>('/organization/getavailableroles', {
      params: {
        orgId,
      },
    })
  },

  updateLegalEntity(orgId: string, payload: OrgLegalEntity) {
    return http.patch<ApiResponseInner<undefined>>('/organization/updatecontractentities', {
      orgId,
      legalEntities: [payload],
    })
  },

  getRequestAccessDetails(orgId: string) {
    return http.get<ApiResponseInner<RequestAccessDetailsResponse>>('/organization/getrequestaccessdetails', {
      params: {
        orgId,
      },
    })
  },
})
