import type { DashboardDataRequest } from '../../../@types/DataRequest'

import { DATA_REQUESTS_CONTROLLER } from './data-requests.common-vars'

export type GetDataRequestsDashboardDTORes = {
  dataRequests: DashboardDataRequest[]
  supplierRecordsCount: number
  franchiseRecordsCount: number
}

export const GET_DATA_REQUESTS_DASHBOARD_SUB_PATH = 'dashboard'
export const GET_DATA_REQUESTS_DASHBOARD_SUB_PATH_V2 = 'dashboard/v2'
export const GET_DATA_REQUESTS_DASHBOARD_PATH = `${DATA_REQUESTS_CONTROLLER}/${GET_DATA_REQUESTS_DASHBOARD_SUB_PATH}`
export const GET_DATA_REQUESTS_DASHBOARD_PATH_V2 = `${DATA_REQUESTS_CONTROLLER}/${GET_DATA_REQUESTS_DASHBOARD_SUB_PATH_V2}`
