// eslint-disable-next-line max-classes-per-file
import { IsArray, IsEmail, IsNumber, IsOptional, IsString, IsUUID, MaxLength, ValidateNested } from 'class-validator'
import { Type } from 'class-transformer'

import { SUPPLIER_CORPORATE_REQUESTS_CONTROLLER } from './supplier-corporate-requests.common-vars'

export class SupplierToCorporateRequest {
  @IsUUID()
  id!: string

  @IsEmail()
  email!: string

  @IsUUID()
  @IsOptional()
  orgId?: string | null

  @IsUUID()
  @IsOptional()
  inviteId?: string | null
}

export class AddSupplierCorporateRequestsDto {
  @IsArray()
  @ValidateNested()
  @Type(() => SupplierToCorporateRequest)
  knownSuppliers!: SupplierToCorporateRequest[]

  @IsArray()
  @ValidateNested()
  @Type(() => SupplierToCorporateRequest)
  unknownSuppliers!: SupplierToCorporateRequest[]

  @IsNumber()
  year!: number

  @IsOptional()
  @IsString()
  @MaxLength(10000)
  message?: string
}

export const ADD_SUPPLIER_CORPORATE_REQUESTS_SUB_PATH = 'bulk'
export const ADD_SUPPLIER_CORPORATE_REQUESTS_PATH = `${SUPPLIER_CORPORATE_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_CORPORATE_REQUESTS_SUB_PATH}`
