import type { ValidationArguments, ValidationOptions } from 'class-validator'
import { registerDecorator } from 'class-validator'
import moment from 'moment'

/**
 * Checks if the current value is a valid year and is before or equal to the current year.
 *
 * @param validationOptions
 * @constructor
 */
export function IsYearBeforeOrEqual(validationOptions?: ValidationOptions) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isYearBeforeOrEqual',
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(yearValue: unknown, _: ValidationArguments) {
          const currentYear = moment().year()
          return typeof yearValue === 'number' && yearValue > 0 && yearValue <= currentYear
        },
      },
    })
  }
}
