// eslint-disable-next-line max-classes-per-file
import {
  ArrayMinSize,
  IsArray,
  IsEmail,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  MaxLength,
  ValidateNested,
} from 'class-validator'
import { Type } from 'class-transformer'

import { SUPPLIER_PRODUCT_REQUESTS_CONTROLLER } from './supplier-product-requests.common-vars'

export const MAX_STRING_LENGTH = 10000

export class ProductIdentifiers {
  @IsString()
  activityCode!: string

  @IsNumber()
  activityRecordId!: number

  @IsNumber()
  datasetId!: number

  @IsNumber()
  productOrgId!: number
}

export class AddSupplierProductRequestDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  supplierId!: string

  @IsString()
  @IsOptional()
  @MaxLength(MAX_STRING_LENGTH)
  message?: string

  @IsNumber()
  year!: number

  @IsArray()
  @ArrayMinSize(1)
  @Type(() => ProductIdentifiers)
  @ValidateNested()
  products!: ProductIdentifiers[]

  @IsString()
  productName!: string
}

export class AddSupplierProductRequestAndInviteDTO extends AddSupplierProductRequestDTO {
  @IsEmail()
  email!: string
}

export class AddSupplierProductRequestAndAssignDTO extends AddSupplierProductRequestDTO {
  @IsUUID()
  supplierOrgId!: string
}

export type AddSupplierKnownProductRequestDTORes = {
  requestId: string
}

export const ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_SUB_PATH = 'known'
export const ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_SUB_PATH}`

export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_SUB_PATH = 'assignment'
export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_SUB_PATH}`

export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_SUB_PATH = 'invite'
export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_SUB_PATH}`
