import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { TOTAL_PRODUCT_QUANTITY_CONTROLLER } from './controller.common-vars'

export const GET_TOTAL_PRODUCT_QUANTITY_YEARS_SUBPATH = 'years'
export const GET_TOTAL_PRODUCT_QUANTITY_YEARS_URI = `${TOTAL_PRODUCT_QUANTITY_CONTROLLER}/${GET_TOTAL_PRODUCT_QUANTITY_YEARS_SUBPATH}`

export class TotalProductQuantityYearsDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type TotalProductQuantityYearsRes = {
  years: number[]
}
