import { IsNumber, IsOptional } from 'class-validator'

import type { SupplierStats } from '../../../@types/suppliers.types'

import { SUPPLIER_DATA_REQUESTS_CONTROLLER } from './supplier-data-requests.common-vars'

export class GetSupplierDataRequestsStatsDTO {
  @IsNumber()
  @IsOptional()
  year?: number
}

export type GetSupplierDataRequestsStatsRes = {
  corporate: SupplierStats
  product: SupplierStats
}

export const GET_SUPPLIER_DATA_REQUESTS_STATS_SUB_PATH = 'stats'
export const GET_SUPPLIER_DATA_REQUESTS_STATS_PATH = `${SUPPLIER_DATA_REQUESTS_CONTROLLER}/${GET_SUPPLIER_DATA_REQUESTS_STATS_SUB_PATH}`
