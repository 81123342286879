import type { AxiosInstance } from 'axios'

import { TAG_TYPES } from '@/imports/@enums/tags.enum.v2'

import { GET_TAG_OPTIONS_URI, type GetTagOptionsDTORes } from '@/imports/lib/DTO/tags/get-aq-tags'

// apis for tags schemaa
export const useTagsApi = (http: AxiosInstance) => ({
  // get AQ tags
  getAqTags(tagTypes: TAG_TYPES[]) {
    return http.post<GetTagOptionsDTORes>(GET_TAG_OPTIONS_URI, { tagTypes })
  },
})
