import type { AxiosInstance } from 'axios'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import {
  type GetSupplierCustomersDTO,
  type GetSupplierCustomersDTORes,
  GET_SUPPLIER_CUSTOMERS_PATH,
} from '@/imports/lib/DTO/supplier-customers/get-supplier-customers.dto'

export const useCustomersApi = (http: AxiosInstance) => ({
  getCustomers(params: GetSupplierCustomersDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetSupplierCustomersDTORes>(GET_SUPPLIER_CUSTOMERS_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
