import type { AxiosInstance } from 'axios'

import { type UpdateInviteEmailDTO, UPDATE_INVITE_EMAIL_PATH } from '@lib/DTO/suppliers/update-invite-email.dto'
import { replaceUriParams } from '@lib/DTO'
import {
  CREATE_BULK_SUPPLIER_CORPORATE_INVITES_PATH,
  type CreateInvitesDTO,
} from '@lib/DTO/suppliers/create-invites.dto'

import { GET_PURCHASE_CATEGORIES, GET_SUPPLIERS_SCOPES, GET_SUPPLIERS_STATUSES } from '@/imports/@enums/suppliers.enums'
import { SORT_ORDER } from '@/imports/@enums/common.enums'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import type {
  InvitePayloadToUnknownSupplier,
  PurchasesTableDataResponse,
  SupplierDetailsResponse,
  SuppliersTableDataResponse,
  AssociatedPurchase,
  AssociatedPurchasesMeta,
} from '@/imports/@types/Supplier.v2'

type Payload<T> = {
  orgId: string
  year: number
  perPage: number
  pageNo: number
  query: string
  goodServiceTypeQuery: string
  sortField: string
  sortOrder: SORT_ORDER
} & T

export interface SuppliersPayload {
  activities: GET_PURCHASE_CATEGORIES[]
  statuses: GET_SUPPLIERS_STATUSES[]
  scopes: GET_SUPPLIERS_SCOPES[]
  accuracyScore?: number[]
}

export interface PurchasesPayload {
  supplierHash?: string
  supplierOrgId?: string
  activities: GET_PURCHASE_CATEGORIES[]
  statuses?: GET_SUPPLIERS_STATUSES[]
}

type SupplierDetailsPayload = {
  orgId: string
  year: number
} & ({ supplierHash: undefined; supplierOrgId: string } | { supplierHash: string; supplierOrgId: undefined })

type GetAssociatedPurchasesPayload = {
  orgId: string
  preferredYear: number
  supplierHash: string
  supplierOrgId: string
  perPage: number
  pageNo: number
  query?: string
  goodServiceTypeQuery?: string
  goodsServiceName?: string
  suggested?: boolean
}

/**
 * @deprecated
 */
export const useSuppliersV2Api = (http: AxiosInstance) => ({
  getSuppliers(params: Payload<SuppliersPayload>) {
    return http.get<SuppliersTableDataResponse>('/supplier/getForOrganization', { params })
  },

  getSupplierDetails({ orgId, year, supplierHash, supplierOrgId }: SupplierDetailsPayload) {
    return http.get<SupplierDetailsResponse>('/supplier/get', {
      params: {
        orgId,
        year,
        supplierHash,
        supplierOrgId,
      },
    })
  },

  getPurchases(params: Payload<PurchasesPayload>) {
    return http.get<PurchasesTableDataResponse>('/purchases/', {
      params,
    })
  },

  sendSupplierInvite(payload: InvitePayloadToUnknownSupplier) {
    return http.post<{ success: boolean }>('/supplier/invite', payload)
  },

  sendSupplierInviteV2(payload: InvitePayloadToUnknownSupplier) {
    return http.post<{ success: boolean }>('/supplier/invite-v2', payload)
  },

  resendInvite({ orgId, inviteId }: { orgId: string; inviteId: string }) {
    return http.post<{ success: boolean; email: string }>(`/supplier/invite/${inviteId}/resend`, { orgId })
  },

  sendSupplierBulkInvite(payload: CreateInvitesDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.post<{ success: boolean }>(CREATE_BULK_SUPPLIER_CORPORATE_INVITES_PATH, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getAssociatedPurchases(payload: GetAssociatedPurchasesPayload) {
    return http.get<{ purchases: AssociatedPurchase[]; meta: AssociatedPurchasesMeta }>('/purchases/associated', {
      params: payload,
    })
  },

  updateInvite(inviteId: string, payload: UpdateInviteEmailDTO) {
    const orgId = useOrganizationStore().id || ''

    const path = replaceUriParams(UPDATE_INVITE_EMAIL_PATH, {
      inviteId,
    })

    return http.patch(path, payload, { headers: { 'x-org-id': orgId } })
  },
})
