import type { AxiosInstance } from 'axios'

import type {
  CustomersTableDataResponse,
  GetCustomersPayload,
} from '@/imports/@types/customersEngagement.deprecated/CustomersEngagement'

/**
 * @deprecated
 */
export const useCustomersEngagementApi = (http: AxiosInstance) => ({
  /**
   * @deprecated
   */
  getCustomers(params: GetCustomersPayload) {
    return http.get<CustomersTableDataResponse>('/supplier-customers', { params })
  },
})
