import { IsNumber, IsOptional, IsString } from 'class-validator'

import type { SupplierPotentialMatch } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierPotentialMatchesByNameDTO {
  @IsString()
  name!: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSupplierPotentialMatchesByNameDTORes = {
  potentialMatches: SupplierPotentialMatch[]
  meta: {
    pageNo: number
    perPage: number
    totalCount: number
  }
}

export const GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_SUB_PATH = 'potential-matches/name'
export const GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_SUB_PATH}`
