import { IsArray, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import type { BaseSupplierPurchase } from '../../../@types/suppliers.types'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_PURCHASES_SORT_FIELDS } from '../../../@enums/purchases.enums'

import { GET_SUPPLIERS_STATUSES } from '../../../@enums/suppliers.enums'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierPurchasesDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsOptional()
  @IsEnum(GET_PURCHASES_SORT_FIELDS)
  sortField?: GET_PURCHASES_SORT_FIELDS

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  activities?: string[]

  @IsString()
  @IsOptional()
  searchQuery?: string

  @IsArray()
  @IsEnum(GET_SUPPLIERS_STATUSES, { each: true })
  @IsOptional()
  statuses?: GET_SUPPLIERS_STATUSES[]
}

export type GetSupplierPurchasesDTORes = {
  totalSpent: number
  purchases: BaseSupplierPurchase[]
  totalAssignedEmissionQuantity: number
  impactPurchaseQuantity: number
  impactLogisticsQuantity: number
  meta: {
    pageNo: number
    perPage: number
    totalCount: number
  }
}

export const GET_SUPPLIER_PURCHASES_SUB_PATH = ':supplierId/purchases'
export const GET_SUPPLIER_PURCHASES_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_PURCHASES_SUB_PATH}`
