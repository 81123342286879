import { IsUUID } from 'class-validator'

import { SUPPLIER_PRODUCT_REQUESTS_CONTROLLER } from './supplier-product-requests.common-vars'

export class FinalizeSupplierProductRequestDto {
  @IsUUID()
  productFootprintId!: string
}

export const FINALIZE_PRODUCT_REQUEST_SUB_PATH = ':requestId/finalize'
export const FINALIZE_PRODUCT_REQUEST_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${FINALIZE_PRODUCT_REQUEST_SUB_PATH}`
