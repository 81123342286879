import { IsUUID } from 'class-validator'

import { SUPPLIER_CORPORATE_REQUESTS_CONTROLLER } from './supplier-corporate-requests.common-vars'

export class FinalizeSupplierCorporateRequestDto {
  @IsUUID()
  footprintId!: string
}

export const FINALIZE_CORPORATE_REQUEST_SUB_PATH = ':requestId/finalize'
export const FINALIZE_CORPORATE_REQUEST_PATH = `${SUPPLIER_CORPORATE_REQUESTS_CONTROLLER}/${FINALIZE_CORPORATE_REQUEST_SUB_PATH}`
