import { IsString, IsEmail, IsOptional, MaxLength } from 'class-validator'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class EditSupplierDTO {
  @IsString()
  @MaxLength(255)
  name!: string

  @IsOptional()
  @IsEmail()
  @MaxLength(255)
  email?: string
}

export type EditSupplierDTORes = {
  supplierId: string
}

export const EDIT_SUPPLIER_SUB_PATH = ':supplierId'
export const EDIT_SUPPLIER_PATH = `${SUPPLIERS_CONTROLLER}/${EDIT_SUPPLIER_SUB_PATH}`
