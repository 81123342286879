import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_SUPPLIER_CUSTOMERS_SORT_FIELDS } from '../../../@enums/supplier-customers.enums'

import type { BaseSupplierCustomer } from '../../../@types/suppliers.types'

import { SUPPLIER_CUSTOMERS_CONTROLLER } from './supplier-customers.common-vars'

export class GetSupplierCustomersDTO {
  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number

  @IsOptional()
  @IsEnum(GET_SUPPLIER_CUSTOMERS_SORT_FIELDS)
  sortField?: GET_SUPPLIER_CUSTOMERS_SORT_FIELDS

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsString()
  @IsOptional()
  query?: string

  @IsString()
  @IsOptional()
  filters?: string
}

export type GetSupplierCustomersDTORes = {
  customers: BaseSupplierCustomer[]
  meta: {
    products: string[]
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIER_CUSTOMERS_SUB_PATH = ''
export const GET_SUPPLIER_CUSTOMERS_PATH = SUPPLIER_CUSTOMERS_CONTROLLER
