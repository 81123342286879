import { IsNumber, IsOptional, IsString } from 'class-validator'

import type { SupplierAssociatedPurchase } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierAssociatedPurchasesDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsString()
  goodsServiceName?: string

  @IsOptional()
  @IsString()
  query?: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSupplierAssociatedPurchasesDTORes = {
  purchases: SupplierAssociatedPurchase[]
  meta: {
    pageNo: number
    perPage: number
    totalCount: number
  }
}

export const GET_SUPPLIER_ASSOCIATED_PURCHASES_SUB_PATH = ':supplierId/associated-purchases'
export const GET_SUPPLIER_ASSOCIATED_PURCHASES_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_ASSOCIATED_PURCHASES_SUB_PATH}`
