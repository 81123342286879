// eslint-disable-next-line max-classes-per-file
import { IsArray, IsEmail, IsUUID, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class SupplierEmailsPayload {
  @IsEmail()
  email!: string

  @IsUUID()
  supplierId!: string
}

export class UpdateSupplierEmailsDTO {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SupplierEmailsPayload)
  supplierEmails!: SupplierEmailsPayload[]
}

export type UpdateSupplierEmailsDTORes = {
  updatedRecords: number
}

export const UPDATE_SUPPLIER_EMAILS_SUB_PATH = 'bulk-emails'
export const UPDATE_SUPPLIER_EMAILS_PATH = `${SUPPLIERS_CONTROLLER}/${UPDATE_SUPPLIER_EMAILS_SUB_PATH}`
