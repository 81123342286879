import { IsUUID } from 'class-validator'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class DetachSupplierDto {
  @IsUUID()
  supplierAssociationId!: string
}

export const DETACH_SUPPLIERS_SUB_PATH = ':supplierId/detach'
export const DETACH_SUPPLIERS_PATH = `${SUPPLIERS_CONTROLLER}/${DETACH_SUPPLIERS_SUB_PATH}`
