import { TEMPORARY_HARDCODED_TAG_TYPES } from '@/imports/@enums/tags.enums'

export const TYPE_OPTIONS: { [key in TEMPORARY_HARDCODED_TAG_TYPES | string]: string[] } = {
  [TEMPORARY_HARDCODED_TAG_TYPES.RENEWABLE]: [
    'Other.Hydro',
    'Other.Photovoltaic Solar',
    'Other.Concentrating Solar Power',
    'Other.Wind',
    'Other.Biofuels',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.TRANSPORT_FUEL_TYPE]: [
    'Gas.Butane',
    'Gas.Compressed Natural Gas (CNG)',
    'Gas.Natural Gas',
    'Gas.Natural Gas.100% Mineral Blend',
    'Gas.Other Petroleum Gas',
    'Gas.Propane',
    'Liquid.Aviation Spirit',
    'Liquid.Aviation Turbine Fuel',
    'Liquid.Burning Oil',
    'Liquid.Diesel.100% Mineral Blend',
    'Liquid.Diesel.Average Biofuel Blend',
    'Liquid.Fuel Oil',
    'Liquid.Gas Oil',
    'Liquid.Liquefied Natural Gas (LNG)',
    'Liquid.Liquefied Petroleum Gas (LPG)',
    'Liquid.Lubricants',
    'Liquid.Marine Fuel Oil',
    'Liquid.Marine Gas Oil',
    'Liquid.Naphtha',
    'Liquid.Petrol.100% Mineral Blend',
    'Liquid.Petrol.100% Mineral Petrol',
    'Liquid.Petrol.Average Biofuel Blend',
    'Liquid.Processed Fuel Oils.Distillate Oil',
    'Liquid.Processed Fuel Oils.Residual Oil',
    'Liquid.Refinery Miscellaneous',
    'Liquid.Waste Oils',
    'Solid.Coal',
    'Solid.Coal.Home Produced Coal',
    'Solid.Coking Coal',
    'Solid.Petroleum Coke',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.HEATING_FUEL_TYPE]: [
    'Aviation Turbine Fuel',
    'Biofuel.Biodiesel',
    'Biofuel.Biodiesel.Development Diesel',
    'Biofuel.Biodiesel.Hydrotreated Vegetable Oil',
    'Biofuel.Biodiesel.Off-Road',
    'Biofuel.Biodiesel.Tallow',
    'Biofuel.Biodiesel.Used Cooking Oil',
    'Biofuel.Bioethanol',
    'Biofuel.Biomethane',
    'Biofuel.Biopetrol',
    'Biofuel.Biopropane',
    'Biofuel.Compressed Biomethane',
    'Biofuel.Development Petrol',
    'Biofuel.Liquefied Biomethane',
    'Biofuel.Methanol (bio)',
    'Biofuel.Renewable petrol',
    'Biogas.Biogas',
    'Biogas.Landfill gas',
    'Biomass.Grass/straw',
    'Biomass.Wood chips',
    'Biomass.Wood logs',
    'Biomass.Wood pellets',
    'Gas.Butane',
    'Gas.Compressed Natural Gas (CNG)',
    'Gas.Natural Gas',
    'Gas.Natural Gas.100% Mineral Blend',
    'Gas.Other Petroleum Gas',
    'Gas.Propane',
    'Liquid.Aviation Spirit',
    'Liquid.Aviation Turbine Fuel',
    'Liquid.Burning Oil',
    'Liquid.Diesel.100% Mineral Blend',
    'Liquid.Diesel.Average Biofuel Blend',
    'Liquid.Fuel Oil',
    'Liquid.Gas Oil',
    'Liquid.Liquefied Natural Gas (LNG)',
    'Liquid.Liquefied Petroleum Gas (LPG)',
    'Liquid.Lubricants',
    'Liquid.Marine Fuel Oil',
    'Liquid.Marine Gas Oil',
    'Liquid.Naphtha',
    'Liquid.Petrol.100% Mineral Blend',
    'Liquid.Petrol.100% Mineral Petrol',
    'Liquid.Petrol.Average Biofuel Blend',
    'Liquid.Processed Fuel Oils.Distillate Oil',
    'Liquid.Processed Fuel Oils.Residual Oil',
    'Liquid.Refinery Miscellaneous',
    'Liquid.Waste Oils',
    'Solid.Coal',
    'Solid.Coal.Home Produced Coal',
    'Solid.Coking Coal',
    'Solid.Petroleum Coke',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.STATIONARY_COMBUSTION_FUEL_TYPE]: [
    'Aviation Turbine Fuel',
    'Biofuel.Biodiesel',
    'Biofuel.Biodiesel (100%)',
    'Biofuel.Biodiesel.Development Diesel',
    'Biofuel.Biodiesel.Hydrotreated Vegetable Oil',
    'Biofuel.Biodiesel.Off-Road',
    'Biofuel.Biodiesel.Tallow',
    'Biofuel.Biodiesel.Used Cooking Oil',
    'Biofuel.Bioethanol',
    'Biofuel.Biomethane',
    'Biofuel.Biopetrol',
    'Biofuel.Biopropane',
    'Biofuel.Compressed Biomethane',
    'Biofuel.Development Petrol',
    'Biofuel.Ethanol (100%)',
    'Biofuel.Liquefied Biomethane',
    'Biofuel.Methanol (bio)',
    'Biofuel.Rendered Animal Fat',
    'Biofuel.Renewable petrol',
    'Biofuel.Vegetable Oil',
    'Biogas.Biogas',
    'Biogas.Landfill gas',
    'Biogas.Other Biomass Gases',
    'Biomass.Bagasse',
    'Biomass.Bamboo',
    'Biomass.Grass/straw',
    'Biomass.North American Hardwood',
    'Biomass.North American Softwood',
    'Biomass.Straw',
    'Biomass.Wood and Wood Residuals',
    'Biomass.Wood chips',
    'Biomass.Wood logs',
    'Biomass.Wood pellets',
    'Gas.Blast Furnace Gas',
    'Gas.Butane',
    'Gas.Butylene',
    'Gas.Coke Oven Gas',
    'Gas.Compressed Natural Gas (CNG)',
    'Gas.Ethane',
    'Gas.Ethylene',
    'Gas.Fuel Gas',
    'Gas.Isobutane',
    'Gas.Isobutylene',
    'Gas.Natural Gas',
    'Gas.Natural Gas.100% Mineral Blend',
    'Gas.Other Petroleum Gas',
    'Gas.Pentanes Plus',
    'Gas.Propane',
    'Gas.Propane Gas',
    'Gas.Propylene',
    'Liquid.Asphalt and Road Oil',
    'Liquid.Aviation Gasoline',
    'Liquid.Aviation Spirit',
    'Liquid.Aviation Turbine Fuel',
    'Liquid.Burning Oil',
    'Liquid.Crude Oil',
    'Liquid.Diesel Fuel',
    'Liquid.Diesel.100% Mineral Blend',
    'Liquid.Diesel.Average Biofuel Blend',
    'Liquid.Distillate Fuel Oil No 1',
    'Liquid.Distillate Fuel Oil No 2',
    'Liquid.Distillate Fuel Oil No 4',
    'Liquid.Fuel Oil',
    'Liquid.Gas Oil',
    'Liquid.Heavy Gas Oils',
    'Liquid.Kerosene',
    'Liquid.Kerosene-Type Jet Fuel',
    'Liquid.Liquefied Natural Gas (LNG)',
    'Liquid.Liquefied Petroleum Gas (LPG)',
    'Liquid.Lubricants',
    'Liquid.Marine Fuel Oil',
    'Liquid.Marine Gas Oil',
    'Liquid.Motor Gasoline',
    'Liquid.Naphtha',
    'Liquid.Natural Gasoline',
    'Liquid.Other Oil (>401 deg F)',
    'Liquid.Petrol.100% Mineral Blend',
    'Liquid.Petrol.100% Mineral Petrol',
    'Liquid.Petrol.Average Biofuel Blend',
    'Liquid.Processed Fuel Oils.Distillate Oil',
    'Liquid.Processed Fuel Oils.Residual Oil',
    'Liquid.Refinery Miscellaneous',
    'Liquid.Residual Fuel Oil',
    'Liquid.Residual Fuel Oil No 5',
    'Liquid.Residual Fuel Oil No 6',
    'Liquid.Special Naphtha',
    'Liquid.Unfinished Oils',
    'Liquid.Used Oil',
    'Liquid.Waste Oils',
    'Other.Petrochemical Feedstocks',
    'Solid.Agricultural Byproducts',
    'Solid.Anthracite Coal',
    'Solid.Bituminous Coal',
    'Solid.Coal',
    'Solid.Coal.Home Produced Coal',
    'Solid.Coking Coal',
    'Solid.Lignite Coal',
    'Solid.Municipal Solid Waste',
    'Solid.Peat',
    'Solid.Petroleum Coke',
    'Solid.Petroleum Coke (Solid)',
    'Solid.Plastics',
    'Solid.Solid Byproducts',
    'Solid.Sub-bituminous Coal',
    'Solid.Tyres',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.WASTE_TYPE]: [
    'Municipal Solid Waste, Anaerobic Digestion',
    'Organics, Anaerobic Digestion',
    'Construction, Combusted',
    'Electrical Items, Combusted',
    'Metal, Combusted',
    'Municipal Solid Waste, Combusted',
    'Organics, Combusted',
    'Paper, Combusted',
    'Plastic, Combusted',
    'Municipal Solid Waste, Composted',
    'Organics, Composted',
    'Paper, Composted',
    'Electrical Items, Landfilled',
    'Metal, Landfilled',
    'Municipal Solid Waste, Landfilled',
    'Organics, Landfilled',
    'Paper, Landfilled',
    'Plastic, Landfilled',
    'Construction, Recycling',
    'Electrical Items, Recycling',
    'Metal, Recycling',
    'Municipal Solid Waste, Recycling',
    'Paper, Recycling',
    'Plastic, Recycling',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.GAS_TYPE]: [
    'C2F6',
    'C2H6O',
    'C3F6',
    'C3F8',
    'C4F10',
    'C5F12',
    'C6F14',
    'CCl4',
    'CF4',
    'CFC-11/R11',
    'CFC-113',
    'CFC-114',
    'CFC-115',
    'CFC-12/R12',
    'CFC-13',
    'CH2Cl2',
    'CH3Br',
    'CH3CCl3',
    'CH3Cl',
    'CH4',
    'CO2',
    'FR12',
    'HCFC-123',
    'HCFC-124',
    'HCFC-141b',
    'HCFC-142b',
    'HCFC-21',
    'HCFC-22/R22',
    'HCFC-225ca',
    'HCFC-225cb',
    'HCFE-235da2',
    'HFC-125',
    'HFC-134',
    'HFC-134a',
    'HFC-143',
    'HFC-143a',
    'HFC-152',
    'HFC-152a',
    'HFC-161',
    'HFC-227ea',
    'HFC-23',
    'HFC-236cb',
    'HFC-236ea',
    'HFC-236fa',
    'HFC-245ca',
    'HFC-245fa',
    'HFC-32',
    'HFC-365mfc',
    'HFC-41',
    'HFC-43-10mee',
    'HFE-125',
    'HFE-134',
    'HFE-143a',
    'HFE-236ca12 (HG-10)',
    'HFE-245cb2',
    'HFE-245fa2',
    'HFE-254cb2',
    'HFE-338pcc13 (HG-01)',
    'HFE-347mcc3',
    'HFE-347pcf2',
    'HFE-356pcc3',
    'HFE-43-10pccc124 (H-Galden1040x)',
    'HFE-449sl (HFE-7100)',
    'HFE-569sf2 (HFE-7200)',
    'Halon-1211',
    'Halon-1301',
    'Halon-2402',
    'MO99',
    'N2O',
    'NF3',
    'NH3',
    'PFC-116',
    'PFC-14',
    'PFC-218',
    'PFC-3-1-10',
    'PFC-318',
    'PFC-4-1-12',
    'PFC-5-1-14',
    'PFC-9-1-18',
    'PFPMIE',
    'R-134a',
    'R-290',
    'R-401A',
    'R-401B',
    'R-401C',
    'R-402A',
    'R-402B',
    'R-403A',
    'R-403B',
    'R-404A',
    'R-405A',
    'R-406A',
    'R-407A',
    'R-407B',
    'R-407C',
    'R-407D',
    'R-407E',
    'R-407F',
    'R-408A',
    'R-409A',
    'R-409B',
    'R-410A',
    'R-410B',
    'R-411A',
    'R-411B',
    'R-412A',
    'R-413A',
    'R-414A',
    'R-414B',
    'R-415A',
    'R-415B',
    'R-416A',
    'R-417A',
    'R-417B',
    'R-417C',
    'R-418A',
    'R-419A',
    'R-419B',
    'R-420A',
    'R-421A',
    'R-421B',
    'R-422A',
    'R-422B',
    'R-422C',
    'R-422D',
    'R-422E',
    'R-423A',
    'R-424A',
    'R-425A',
    'R-426A',
    'R-427A',
    'R-428A',
    'R-429A',
    'R-430A',
    'R-431A',
    'R-432A',
    'R-433A',
    'R-433B',
    'R-433C',
    'R-434A',
    'R-435A',
    'R-436A',
    'R-436B',
    'R-437A',
    'R-438A',
    'R-439A',
    'R-440A',
    'R-441A',
    'R-442A',
    'R-443A',
    'R-444A',
    'R-445A',
    'R-448A',
    'R-500',
    'R-501',
    'R-502',
    'R-503',
    'R-504',
    'R-505',
    'R-506',
    'R-507',
    'R-507A',
    'R-508A',
    'R-508B',
    'R-509A',
    'R-510A',
    'R-511A',
    'R-512A',
    'R-600A',
    'R-717',
    'R11',
    'R32',
    'R407C',
    'R409A',
    'R452A',
    'R454B',
    'R454C',
    'SF5CF3',
    'SF6',
    'c-C4F8',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.REFRIGERANT_TYPE]: [
    'C2F6',
    'C2H6O',
    'C3F6',
    'C3F8',
    'C4F10',
    'C5F12',
    'C6F14',
    'CCl4',
    'CF4',
    'CFC-11/R11',
    'CFC-113',
    'CFC-114',
    'CFC-115',
    'CFC-12/R12',
    'CFC-13',
    'CH2Cl2',
    'CH3Br',
    'CH3CCl3',
    'CH3Cl',
    'CH4',
    'CO2',
    'FR12',
    'HCFC-123',
    'HCFC-124',
    'HCFC-141b',
    'HCFC-142b',
    'HCFC-21',
    'HCFC-22/R22',
    'HCFC-225ca',
    'HCFC-225cb',
    'HCFE-235da2',
    'HFC-125',
    'HFC-134',
    'HFC-134a',
    'HFC-143',
    'HFC-143a',
    'HFC-152',
    'HFC-152a',
    'HFC-161',
    'HFC-227ea',
    'HFC-23',
    'HFC-236cb',
    'HFC-236ea',
    'HFC-236fa',
    'HFC-245ca',
    'HFC-245fa',
    'HFC-32',
    'HFC-365mfc',
    'HFC-41',
    'HFC-43-10mee',
    'HFE-125',
    'HFE-134',
    'HFE-143a',
    'HFE-236ca12 (HG-10)',
    'HFE-245cb2',
    'HFE-245fa2',
    'HFE-254cb2',
    'HFE-338pcc13 (HG-01)',
    'HFE-347mcc3',
    'HFE-347pcf2',
    'HFE-356pcc3',
    'HFE-43-10pccc124 (H-Galden1040x)',
    'HFE-449sl (HFE-7100)',
    'HFE-569sf2 (HFE-7200)',
    'Halon-1211',
    'Halon-1301',
    'Halon-2402',
    'MO99',
    'N2O',
    'NF3',
    'NH3',
    'PFC-116',
    'PFC-14',
    'PFC-218',
    'PFC-3-1-10',
    'PFC-318',
    'PFC-4-1-12',
    'PFC-5-1-14',
    'PFC-9-1-18',
    'PFPMIE',
    'R-134a',
    'R-290',
    'R-401A',
    'R-401B',
    'R-401C',
    'R-402A',
    'R-402B',
    'R-403A',
    'R-403B',
    'R-404A',
    'R-405A',
    'R-406A',
    'R-407A',
    'R-407B',
    'R-407C',
    'R-407D',
    'R-407E',
    'R-407F',
    'R-408A',
    'R-409A',
    'R-409B',
    'R-410A',
    'R-410B',
    'R-411A',
    'R-411B',
    'R-412A',
    'R-413A',
    'R-414A',
    'R-414B',
    'R-415A',
    'R-415B',
    'R-416A',
    'R-417A',
    'R-417B',
    'R-417C',
    'R-418A',
    'R-419A',
    'R-419B',
    'R-420A',
    'R-421A',
    'R-421B',
    'R-422A',
    'R-422B',
    'R-422C',
    'R-422D',
    'R-422E',
    'R-423A',
    'R-424A',
    'R-425A',
    'R-426A',
    'R-427A',
    'R-428A',
    'R-429A',
    'R-430A',
    'R-431A',
    'R-432A',
    'R-433A',
    'R-433B',
    'R-433C',
    'R-434A',
    'R-435A',
    'R-436A',
    'R-436B',
    'R-437A',
    'R-438A',
    'R-439A',
    'R-440A',
    'R-441A',
    'R-442A',
    'R-443A',
    'R-444A',
    'R-445A',
    'R-448A',
    'R-500',
    'R-501',
    'R-502',
    'R-503',
    'R-504',
    'R-505',
    'R-506',
    'R-507',
    'R-507A',
    'R-508A',
    'R-508B',
    'R-509A',
    'R-510A',
    'R-511A',
    'R-512A',
    'R-600A',
    'R-717',
    'R11',
    'R32',
    'R407C',
    'R409A',
    'R452A',
    'R454B',
    'R454C',
    'SF5CF3',
    'SF6',
    'c-C4F8',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.DIRECT_EMISSIONS]: [
    'C2F6',
    'C2H6O',
    'C3F6',
    'C3F8',
    'C4F10',
    'C5F12',
    'C6F14',
    'CCl4',
    'CF4',
    'CFC-11/R11',
    'CFC-113',
    'CFC-114',
    'CFC-115',
    'CFC-12/R12',
    'CFC-13',
    'CH2Cl2',
    'CH3Br',
    'CH3CCl3',
    'CH3Cl',
    'CH4',
    'CO2',
    'Excluded - Not relevant in GHG Baseline',
    'FR12',
    'HCFC-123',
    'HCFC-124',
    'HCFC-141b',
    'HCFC-142b',
    'HCFC-21',
    'HCFC-22/R22',
    'HCFC-225ca',
    'HCFC-225cb',
    'HCFE-235da2',
    'HFC-125',
    'HFC-134',
    'HFC-134a',
    'HFC-143',
    'HFC-143a',
    'HFC-152',
    'HFC-152a',
    'HFC-161',
    'HFC-227ea',
    'HFC-23',
    'HFC-236cb',
    'HFC-236ea',
    'HFC-236fa',
    'HFC-245ca',
    'HFC-245fa',
    'HFC-32',
    'HFC-365mfc',
    'HFC-41',
    'HFC-43-10mee',
    'HFE-125',
    'HFE-134',
    'HFE-143a',
    'HFE-236ca12 (HG-10)',
    'HFE-245cb2',
    'HFE-245fa2',
    'HFE-254cb2',
    'HFE-338pcc13 (HG-01)',
    'HFE-347mcc3',
    'HFE-347pcf2',
    'HFE-356pcc3',
    'HFE-43-10pccc124 (H-Galden1040x)',
    'HFE-449sl (HFE-7100)',
    'HFE-569sf2 (HFE-7200)',
    'Halon-1211',
    'Halon-1301',
    'Halon-2402',
    'MO99',
    'N2O',
    'N2O - 0-0-0 MICRO MIX',
    'N2O - 0-0-0 MICRO MIX PLUS',
    'N2O - 0-52-0',
    'N2O - 10-34-0',
    'N2O - 11-44-13',
    'N2O - 11.4-8.2-8.3.2S',
    'N2O - 12-0-0',
    'N2O - 12-8-20',
    'N2O - 15-15-15',
    'N2O - 2.5% Boron 4-0-0',
    'N2O - 20-10-20',
    'N2O - 3% Magnesium',
    'N2O - 3-0-11 + LH',
    'N2O - 3-0-11 Potassium Nitrate',
    'N2O - 4% Manganese',
    'N2O - 4-6-1',
    'N2O - 4.5% Iron 4-0-0 (ca)',
    'N2O - 46-0-0 Spray Grade Urea Fertilizer',
    'N2O - 5% Copper 4-0-0',
    'N2O - 6-6-6 w/minors',
    'N2O - 6.5% Zinc 4-0-0',
    'N2O - 7% Calcium 5-0-0',
    'N2O - 8-0-0',
    'N2O - 8-0-0 (foliar)',
    'N2O - ALGACELL NITROGEN 16-0-0',
    'N2O - ALGACELL NUTRI-PLUS 2.5-0-0',
    'N2O - AN 20 20-0-0',
    'N2O - AN20',
    'N2O - APELSA FON TO WIN FERTILIZANTE ORGANICO 12-6-1',
    'N2O - Acadian 100% Liquid Seaweed Concentrate',
    'N2O - Actagro Blend 3',
    'N2O - Actagro Micro Select 4-0-0 (-)',
    'N2O - Agroblen 17-5-11',
    'N2O - Agrothrive LFP 2-7-0.3',
    'N2O - Ammonium Sulfate 21-0-0',
    'N2O - B Sure 0.5-0-0.5',
    'N2O - BIOMIC PRODUCTO BIOLOGICO MULTIFUNCIONAL 5-5-5',
    'N2O - BIOSTRATO BIOMAC 2-5-0',
    'N2O - Brexil Multi',
    'N2O - CAN 17 17-0-0',
    'N2O - CF MIX #2',
    'N2O - CF MIX #3',
    'N2O - CF MIX #4',
    'N2O - CF MIX #5',
    'N2O - CF Mix Summer',
    'N2O - CN-9',
    'N2O - CN9 9-0-0',
    'N2O - CODICOBRE FERTILIZANTE INORGANICO LIQUIDO 1-0-1',
    'N2O - CPS BLEND 3',
    'N2O - CPS BLEND 4',
    'N2O - CPS Blend #7',
    'N2O - CalNit 15% 16-0-0',
    'N2O - Calcinit 16-0-0',
    'N2O - Calexin',
    'N2O - Can 17',
    'N2O - Carbon Penetrant',
    'N2O - Cell Force 3-0-0',
    'N2O - Cell Force Max 6-0-0',
    'N2O - DIAMOND GROW HUMI[K] BIO WSP 1-0-14 WATER SOLUBLE POWDER',
    'N2O - ELEMENT SN-7',
    'N2O - Element 4-6-1',
    'N2O - FON SUPERIOR FERTILIZANTE ORGANICO PELLETS 13-0-0',
    'N2O - Fertigard 2-41-27',
    'N2O - Fertilizer Mix A',
    'N2O - Fertilizer Mix B',
    'N2O - Formula 1 0-60-0',
    'N2O - GENESYS N-FORCE 8-0-0',
    "N2O - GROWER'S SECRET NITROGEN 14-0-0",
    'N2O - GRUINDAG FITOCUAJE FERTILIZANTE PARA FLOREACION Y CUAJADO DE HORTICOLAS 6-4-0',
    'N2O - GRUINDAG RAIGREEN ENRAIZADOR CON AMINOACIDOS 5-4-4',
    'N2O - Gypsum',
    'N2O - IDAI FERTIREG 2-4-0',
    'N2O - ISM BIOFERTIL NPK 1-0-0',
    'N2O - Integrity Z-422 Soluable Micronutrients 1.5-0-0',
    'N2O - Iron Sequestar',
    'N2O - KELPRO ALGAS MARINAS FERTILIZANTE ORGANICO LIQUIDO 0-0-1',
    'N2O - KTS',
    'N2O - KTS+LH',
    'N2O - Katalyst Fs 0-0-30 (ca)',
    'N2O - Keep 0-52-34',
    'N2O - KeyPlex 1200 DP',
    'N2O - KeyPlex 350',
    'N2O - Keyplex 250 DP',
    'N2O - L.B. Urea',
    'N2O - Liquid Humus 0-0-4 (ca)',
    'N2O - Liquid S.T.E.M.',
    'N2O - Lokomotive 2-0-25',
    'N2O - Low Biuret Urea 46-0-0',
    'N2O - Magnesium Nitrate',
    'N2O - Magnesium Nitrate Beads (-)',
    'N2O - Magnisol',
    'N2O - Manganese Sulfate Powder 31%',
    'N2O - Maximo 360 Zinc Sulfate (powder)',
    'N2O - Mega Amino 3-0-5 (-)',
    'N2O - Metalosate Boron Amino Acid Complex Liquid Foliar Fertilizer (ca)',
    'N2O - Metalosate Calcium Amino Acid Chelate Liquid Fertilizer',
    'N2O - Metalosate Iron Amino Acid Complex Liquid Foliar Fertilizer (ca)',
    'N2O - Micromax Micronutrients',
    'N2O - Microminor 0.4-0-0 2FE, 4MN, 4ZN',
    'N2O - Microplex',
    'N2O - Micros + AC16',
    'N2O - Miller Nu Film P',
    'N2O - Millerplex 3-3-3',
    'N2O - Monarch 2-20-15',
    'N2O - N-Pact 26-0-0',
    'N2O - NATURAMIN-WSP 80% SOLUBLE MICROGRANULES',
    'N2O - NF #2 Blend',
    'N2O - NF #3 Blend (-)',
    'N2O - NFIC Blend',
    'N2O - NH- PLUS FERTILIZANTE ÓRGANO-MINERAL LIQUIDO SOLUBLE',
    'N2O - NH-Amin BIOESTIMULANTE DEL CRECIMIENTO VEGETAL',
    'N2O - NH-CALCIO CALCIO EXENTO DE CLORUROS, NITRATOS Y SULFATOS',
    'N2O - NH-K POTASIO EXENTO DE CLORUROS, NITRATOS Y SULFATOS',
    'N2O - NH-Mg MAGNESIO EXENTO DE CLORUROS, NITRATOS Y SULFATOS',
    'N2O - Nphuric + IronForce 14-0-0',
    'N2O - Nutri-Leaf 20-20-20',
    'N2O - Nutri-Phite P Foliar 4-30-8',
    'N2O - Nutri-Phite P+k 0-28-26',
    'N2O - Nutri-Phite Plus Calci-Phite',
    'N2O - Nutri-Phite Plus Magnum 2-40-16 (ca)',
    'N2O - Nutri-Phite Plus P+k 0-28-26 (az & Ca)',
    'N2O - Nutrisync Essential',
    'N2O - ONEUP 4-14-5',
    'N2O - PHYTA-GROW LEAFY GREEN SPECIAL 7-1-2',
    'N2O - POTASSIUM NITRATE (13-0-46)',
    'N2O - PROIN 8-0-0',
    'N2O - PROIN N8 8-0-0',
    'N2O - PeneGator',
    'N2O - Phocon 3-12-3 (ca)',
    'N2O - Phortress 0-37*-24',
    'N2O - Plant Plus 0-0-2',
    'N2O - Potassium Nitrate (13.7-0-46)',
    'N2O - Primacy ALPHA 3-0-7',
    'N2O - Pro-Zinc 10 8-0-0',
    'N2O - Resist 0-0-21',
    'N2O - Resist 0-27-21',
    'N2O - Resist SW 0-16-17',
    'N2O - Riser',
    'N2O - SST Calcium',
    'N2O - STIMPLEX CROP BIOSTIMULANT 0-3-2',
    'N2O - Sequestrene 138 FE',
    'N2O - Siapton 10-0-0 2X2.5GA',
    'N2O - Smart B-Mo',
    'N2O - SoluClear 11-44-13',
    'N2O - SoluClear 15-6-26',
    'N2O - SoluClear 20-20-20',
    'N2O - SoluClear 6-31-31',
    'N2O - Solubor (20.5%) (-)',
    'N2O - StarPhite 0-28-25',
    'N2O - Sulfate of Potash (K2SO4) (-)',
    'N2O - TECAMIN BIONUTRIENTE AMINOACIDOS DE ORIGEN VEGETAL 7-0-0',
    'N2O - TIERRA FERTIL 5-7-1',
    'N2O - TRUE 1-4-0',
    'N2O - TRUE 4-2-2',
    'N2O - TRUE 4-2-2 Plus',
    'N2O - TRUE 7-0-0',
    'N2O - TRUE BIO-BULDER 0.5-0-1',
    'N2O - TRUE ORGANIC PRODUCTS INC TRUE 512 5-1-2',
    'N2O - TRUE ORGANIC PRODUCTS, INC TRUE 4-2-2',
    'N2O - Take Off Magnum 2-40-16',
    'N2O - Take Off P+K 0-28-25',
    'N2O - Tech-Flo All Season Blend #1 2-6-2',
    'N2O - Tech-Flo Beta 0-6-0',
    'N2O - Tech-Spray Mo-Power 4-2-1',
    'N2O - Turf Supreme 16-6-8',
    'N2O - UAN-32',
    'N2O - UN32',
    'N2O - Ultrasol 13-0-46',
    'N2O - Urea 46-0-0',
    'N2O - VIOLETA 13-44-13',
    'N2O - Versatile Manganese',
    'N2O - WAM 0-0-24 -16',
    'N2O - WAM 15-4-5',
    'N2O - WAM 17-4-0',
    'N2O - WAM 19-2-4 (-)',
    'N2O - WAM 25-3-0 (-)',
    'N2O - WAM 28 (14.8-00-0.1-7.375 Ca)',
    'N2O - WAM 28 Chaser',
    'N2O - WAM 3-9-12 (-)',
    'N2O - WAM 30 Humic',
    'N2O - WAM 45',
    'N2O - WAM 45 Chaser',
    'N2O - WAM BOA',
    'N2O - WAM CaTS Watermaxx Blend #1',
    'N2O - WAM Foliar Blend 1',
    'N2O - WAM Foliar Blend 2 - 7.5-00-5.7',
    'N2O - WAM Foliar Blend 3 (5.2-0.5-10.5)',
    'N2O - WAM P3 13-2-3-3-6',
    'N2O - WAM Pistachio 1 (12.4-00-0.1-6.176 Ca)',
    'N2O - WAM Pistachio Foliar 1',
    'N2O - WAM Pistachio Foliar 2',
    'N2O - WaterSolv',
    'N2O - Wiser Organic 3-2-2',
    'N2O - Wisergnic 3-2-2',
    'N2O - Zmc Express',
    'NF3',
    'NH3',
    'PFC-116',
    'PFC-14',
    'PFC-218',
    'PFC-3-1-10',
    'PFC-318',
    'PFC-4-1-12',
    'PFC-5-1-14',
    'PFC-9-1-18',
    'PFPMIE',
    'R-134a',
    'R-290',
    'R-401A',
    'R-401B',
    'R-401C',
    'R-402A',
    'R-402B',
    'R-403A',
    'R-403B',
    'R-404A',
    'R-405A',
    'R-406A',
    'R-407A',
    'R-407B',
    'R-407C',
    'R-407D',
    'R-407E',
    'R-407F',
    'R-408A',
    'R-409A',
    'R-409B',
    'R-410A',
    'R-410B',
    'R-411A',
    'R-411B',
    'R-412A',
    'R-413A',
    'R-414A',
    'R-414B',
    'R-415A',
    'R-415B',
    'R-416A',
    'R-417A',
    'R-417B',
    'R-417C',
    'R-418A',
    'R-419A',
    'R-419B',
    'R-420A',
    'R-421A',
    'R-421B',
    'R-422A',
    'R-422B',
    'R-422C',
    'R-422D',
    'R-422E',
    'R-423A',
    'R-424A',
    'R-425A',
    'R-426A',
    'R-427A',
    'R-428A',
    'R-429A',
    'R-430A',
    'R-431A',
    'R-432A',
    'R-433A',
    'R-433B',
    'R-433C',
    'R-434A',
    'R-435A',
    'R-436A',
    'R-436B',
    'R-437A',
    'R-438A',
    'R-439A',
    'R-440A',
    'R-441A',
    'R-442A',
    'R-443A',
    'R-444A',
    'R-445A',
    'R-448A',
    'R-500',
    'R-501',
    'R-502',
    'R-503',
    'R-504',
    'R-505',
    'R-506',
    'R-507',
    'R-507A',
    'R-508A',
    'R-508B',
    'R-509A',
    'R-510A',
    'R-511A',
    'R-512A',
    'R-600A',
    'R-717',
    'R11',
    'R32',
    'R407C',
    'R409A',
    'R452A',
    'R454B',
    'R454C',
    'SF5CF3',
    'SF6',
    'c-C4F8',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.GOODS_SERVICE_TYPE]: [
    'Air transport',
    'Inland water transport',
    'Sea and coastal water transport',
    'Other land transport',
    'Transport via railways',
  ],
  [TEMPORARY_HARDCODED_TAG_TYPES.ENERGY_GENERATION_TYPE]: [
    'Liquid.Processed Fuel Oils.Distillate Oil',
    'Liquid.Petrol.100% Mineral Blend',
    'Liquid.Fuel Oil.Average Biofuel Blend',
    'Biofuel.Renewable petrol',
    'Biogas.Other Biomass Gases',
    'Liquid.Lubricants',
    'Solid.Coking Coal',
    'Unknown',
    'Other.Battery Electric Vehicle',
    'Solid.Peat',
    'Gas.Fuel Gas',
    'Biomass.Bagasse',
    'Solid.Petroleum Coke',
    'Biofuel.Vegetable Oil',
    'Liquid.Unknown',
    'Biogas.Landfill gas',
    'Gas.Propane Gas',
    'Biofuel.Methanol (bio)',
    'Liquid.Kerosene-Type Jet Fuel',
    'Gas.Isobutylene',
    'Biomass.North American Hardwood',
    'Gas.Pentanes Plus',
    '100% Mineral Blend',
    'Liquid.Distillate Fuel Oil No 1',
    'Liquid.Natural Gasoline',
    'Gas.Blast Furnace Gas',
    'Biomass.Wood chips',
    'Biofuel.Aviation Spirit',
    'Solid.Municipal Solid Waste',
    'Solid.Lignite Coal',
    'Biomass.Wood and Wood Residuals',
    'Biomass.Straw',
    'Liquid.Residual Fuel Oil No 5',
    'Liquid.Motor Gasoline',
    'Biofuel.Biodiesel.Off-Road',
    'Liquid.Asphalt and Road Oil',
    'Liquid',
    'Liquid.Distillate Fuel Oil No 4',
    'Liquid.Marine Gas Oil',
    'Biofuel.Biodiesel.Hydrotreated Vegetable Oil',
    'Liquid.Liquefied Petroleum Gas (LPG).Average Biofuel Blend',
    'Liquid.Diesel Fuel',
    'Liquid.Diesel',
    'Solid.Bituminous Coal',
    'Liquid.Residual Fuel Oil',
    'Biofuel.Diesel.Average Biofuel Blend',
    'Solid.Anthracite Coal',
    'Liquid.Special Naphtha',
    'Aviation Turbine Fuel',
    'Gas.Coke Oven Gas',
    'Liquid.Residual Fuel Oil No 6',
    'Biofuel.Liquefied Biomethane',
    'Gas.Compressed Natural Gas (CNG)',
    'Biofuel.Aviation Spirit.Average Biofuel Blend',
    'Biomass.Wood pellets',
    'Liquid.Liquefied Petroleum Gas (LPG).100% Mineral Blend',
    'Liquid.Diesel.100% Mineral Blend',
    'Biomass.Bamboo',
    'Liquid.Aviation Spirit',
    'Other.Hybrid',
    'Gas.Propane',
    'Liquid.Kerosene',
    'Liquid.Burning Oil',
    'Solid.Agricultural Byproducts',
    'Liquid.Petrol.Average Biofuel Blend',
    'Biogas.Biogas',
    'Solid.Plastics',
    'Gas.Isobutane',
    'Biofuel.Compressed Biomethane',
    'Liquid.Crude Oil',
    'Liquid.Gas Oil',
    'Liquid.Processed Fuel Oils.Residual Oil',
    'Liquid.Diesel.Average Biofuel Blend',
    'Liquid.Marine Fuel Oil',
    'Biofuel.Biodiesel.Tallow',
    'Solid.Tyres',
    'Biofuel',
    'Gas.Other Petroleum Gas',
    'Liquid.Liquefied Petroleum Gas (LPG)',
    'Biomass.Grass/straw',
    'Biofuel.Rendered Animal Fat',
    'Liquid.Heavy Gas Oils',
    'Gas.Natural Gas.100% Mineral Blend',
    'Biofuel.Aviation Spirit.100% Mineral Blend',
    'Gas.Ethylene',
    'Liquid.Aviation Gasoline',
    'Liquid.Distillate Fuel Oil No 2',
    'Biomass.Wood logs',
    'Solid.Petroleum Coke (Solid)',
    'Solid.Sub-bituminous Coal',
    'Liquid.Refinery Miscellaneous',
    'Other.Plug-In Hybrid Electric Vehicle',
    'Liquid.Waste Oils',
    'Biofuel.Biodiesel (100%)',
    'Gas.Propylene',
    'Liquid.Aviation Turbine Fuel',
    'Solid.Coal',
    'Liquid.Unfinished Oils',
    'Gas.Natural Gas',
    'Biofuel.Biodiesel.Used Cooking Oil',
    'Solid.Coal.Home Produced Coal',
    'Biofuel.Biomethane',
    'Biofuel.Biopetrol',
    'Liquid.Petrol',
    'Liquid.Other Oil (>401 deg F)',
    'Liquid.Naphtha',
    'Liquid.Aviation Spirit.100% Mineral Blend',
    'Liquid.Fuel Oil',
    'Other.Unknown',
    'Biofuel.Biodiesel',
    'Liquid.Distillate Oil',
    'Gas.Butane',
    'Gas.Ethane',
    'Biofuel.Ethanol (100%)',
    'Biomass.North American Softwood',
    'Biofuel.Biopropane',
    'Gas.100% Mineral Blend',
    'Liquid.Burning Oil.Average Biofuel Blend',
    'Gas',
    'Solid.Solid Byproducts',
    'Biofuel.Bioethanol',
    'Liquid.Used Oil',
    'Gas.Butylene',
    'Liquid.Plug-In Hybrid Electric Vehicle',
    'Gas.Methane',
    'Biomass.Coffee Grounds',
    'Liquid.Liquefied Natural Gas (LNG)',
    'Other.Natural Gas (50%) Biomass (50%)',
    'Other.Petrochemical Feedstocks',
    'Excluded - Not relevant in GHG Baseline',
    'Biofuel.Biodiesel.Development Diesel',
    'Biofuel.Development Petrol',
    'Liquid.Petrol.100% Mineral Petrol',
    'Other.CNG/Petrol',
    'Other.LPG/Petrol',
    'Gas.CNG',
    'Liquid.LNG',
    'Liquid.E85',
    'Liquid.Ethanol',
    'Biofuel.Biodiesel.30% Biodiesel, 70% Mineral Diesel Blend',
    'Other.Biofuels',
    'Other.Hydro',
    'Other.Wind',
    'Other.Concentrating Solar Power',
    'Other.Photovoltaic Solar',
  ],
} as { [key in TEMPORARY_HARDCODED_TAG_TYPES]: string[] }
