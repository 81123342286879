export enum DATA_REQUEST_STATUSES {
  NEW = 'new',
  SHARED = 'shared',
  UNAVAILABLE = 'unavailable',
}

export enum DATA_REQUEST_TYPES {
  CORPORATE_FOOTPRINT = 'corporateFootprint',
  PRODUCT_FOOTPRINT = 'productFootprint',
}

export enum DATA_REQUEST_MODULES {
  FRANCHISE = 'franchise',
  SUPPLIER = 'supplier',
}

export enum REQUEST_TYPES {
  CORPORATE = 'corporate',
  PRODUCT = 'product',
}

export enum DATA_REQUEST_SORT_FIELD {
  ORG_NAME = 'orgName',
  CREATED_AT = 'createdAt',
  TYPE = 'type',
  STATUS = 'status',
}

export enum SUPPLIER_DATA_REQUEST_PROGRESS_STATUSES {
  RESPONDED = 'responded',
  IN_PROGRESS = 'inProgress',
  INVITE_PENDING = 'invitePending',
}

export enum SCOPES_NAME {
  scopeOne = 'scopeOne',
  scopeTwo = 'scopeTwo',
  scopeThree = 'scopeThree',
}
