// eslint-disable-next-line max-classes-per-file
import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { TOTAL_PRODUCT_QUANTITY_CONTROLLER } from './controller.common-vars'

export const GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_SUBPATH = '/facility-names'
export const GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_URI = `${TOTAL_PRODUCT_QUANTITY_CONTROLLER}${GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_SUBPATH}`

export class FacilityNamesTotalProductQuantityDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type FacilityNamesTotalProductQuantityDTORes = {
  facilityNames: {
    id: string
    name: string
  }[]
}
