import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import type {
  CorpInitiativeDetails,
  CorpInitiativesTableSettings,
  CorporateInitiative,
  CorpInitiativeCalculations,
  ProductOption,
  SupplierOption,
  ScenarioStatistics,
  CorpInitiativeScenario,
} from '@/imports/@types/corporateInitiative'
import type { RecommendationStructure } from '@/imports/@types/recommendation/RecommendationStructure'
import type { RecommendationUpdateValues } from '@/imports/@types/recommendation/RecommendationUpdateValues'

interface GetCorporateInitiativesResponse {
  initiatives: CorporateInitiative[]
  meta: {
    totalCount: number
  }
}

interface CreateCorporateInitiativeResponse {
  recommendation: RecommendationStructure
}

interface GetCorporateInitiativeDetailsResponse {
  details: CorpInitiativeDetails
}

interface GetCorporateInitiativeCalculationsResponse {
  calculations: CorpInitiativeCalculations
}

interface GetProductDataYearsResponse {
  years: number[]
}

interface MetaData {
  totalCount: number
  perPage: number
  pageNo: number
}

interface GetCorporateInitiativeProductsResponse {
  products: ProductOption[]
  meta: MetaData
}

interface GetCorporateInitiativeSuppliersResponse {
  suppliers: SupplierOption[]
  meta: MetaData
}

interface GetCorporateInitiativeStatisticsResponse {
  statistics: ScenarioStatistics
}

interface GetCorporateInitiativeScenarioResponse {
  scenario: CorpInitiativeScenario
}

interface GetCorporateInitiativesPayload extends CorpInitiativesTableSettings {
  orgId: string
}

interface GetCorporateInitiativesProductsPayload {
  orgId: string
  year: number
  perPage: number
  pageNo: number
  query: string
}

interface GetCorporateInitiativesSuppliersPayload extends GetCorporateInitiativesProductsPayload {
  goodsServiceTypeTagNames?: string[]
}

interface GetCorporateInitiativesStatisticsPayload {
  orgId: string
  year: number
  goodsServiceTypeTagNames?: string[]
  supplierHashes?: string[]
}

interface UpdateScenarioPayload {
  orgId: string
  baselineYear: number
  startYear: number
  endYear: number
  baselineEmissionFactor: number
  baselineUnit: string
  recommendedUnit: string
  recommendedEmissionFactor: number
  recommendedScenarioName: string
  goodsServiceTypeTagNames?: string[]
  supplierHashes?: string[]
}

export const useCorporateInitiativesApi = (http: AxiosInstance) => ({
  getInitiatives(params: GetCorporateInitiativesPayload) {
    return http.get<GetCorporateInitiativesResponse>('/recommendations-v2', {
      params,
    })
  },

  remove(orgId: string, initiativeId: string) {
    return http.delete<ApiResponseInner<undefined>>(`/recommendations-v2/${initiativeId}`, { params: { orgId } })
  },

  create({ orgId, activityId }: { orgId: string; activityId: number }) {
    return http.post<CreateCorporateInitiativeResponse>('/recommendations-v2/create', {
      orgId,
      activityId,
    })
  },

  update({ orgId, initiativeId, fields }: { orgId: string; initiativeId: string; fields: RecommendationUpdateValues }) {
    return http.patch<CreateCorporateInitiativeResponse>(`/recommendations-v2/${initiativeId}`, {
      orgId,
      fields,
    })
  },

  getDetails(initiativeId: string, orgId: string) {
    return http.get<GetCorporateInitiativeDetailsResponse>(`/recommendations-v2/${initiativeId}/details`, {
      params: {
        orgId,
      },
    })
  },

  getCalculations(initiativeId: string, orgId: string, chartView?: string) {
    return http.get<GetCorporateInitiativeCalculationsResponse>(`/recommendations-v2/${initiativeId}/calculations`, {
      params: {
        orgId,
        chartView,
      },
    })
  },

  getProducts(params: GetCorporateInitiativesProductsPayload) {
    return http.get<GetCorporateInitiativeProductsResponse>('/recommendations-v2/scenario/products', {
      params,
    })
  },

  // This is deprecated by rollout-supplier-engagement-v3
  getSuppliers(params: GetCorporateInitiativesSuppliersPayload) {
    return http.get<GetCorporateInitiativeSuppliersResponse>('/recommendations-v2/scenario/suppliers', {
      params,
    })
  },

  getSuppliersV2(params: GetCorporateInitiativesSuppliersPayload) {
    return http.get<GetCorporateInitiativeSuppliersResponse>('/recommendations-v2/scenario/suppliers/v2', {
      params,
    })
  },

  getStatistics(params: GetCorporateInitiativesStatisticsPayload) {
    return http.get<GetCorporateInitiativeStatisticsResponse>('/recommendations-v2/scenario/statistics', {
      params,
    })
  },

  updateScenario(initiativeId: string, params: UpdateScenarioPayload) {
    return http.patch<{ success: boolean }>(`/recommendations-v2/${initiativeId}/scenario`, {
      ...params,
    })
  },

  getScenario(initiativeId: string, orgId: string) {
    return http.get<GetCorporateInitiativeScenarioResponse>(`/recommendations-v2/${initiativeId}/scenario`, {
      params: {
        orgId,
      },
    })
  },

  getProductDataYears(orgId: string) {
    return http.get<GetProductDataYearsResponse>(`/recommendations-v2/data-years`, {
      params: {
        orgId,
      },
    })
  },
})
