export enum GET_SUPPLIERS_STATUSES {
  INVITE_PENDING = 'invite_pending',
  UNAVAILABLE = 'unavailable',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  INVITE_NOT_SENT = 'invite_not_sent',
}

export enum GET_SUPPLIERS_SCOPES {
  SCOPE_12 = 'scope12',
  SCOPE_123 = 'scope123',
  SCOPE_12_PARTS_OF_3 = 'scope12partsof3',
}

export enum GET_SUPPLIERS_SORT_FIELDS {
  SUPPLIER_NAME = 'supplierName',
  ASSIGNED_EMISSION_QUANTITY = 'assignedEmissionQuantity',
  NAME = 'name', // TODO: remove after supplier v1 cleanup
  TOTAL_EMISSIONS = 'totalEmissions',
}

export enum GET_UNREQUESTED_SUPPLIERS_SORT_FIELDS {
  SUPPLIER_NAME = 'name',
  TOTAL_EMISSIONS = 'totalEmissions',
}

export enum GET_PURCHASE_CATEGORIES {
  INDIRECT_PURCHASES = 'IndirectPurchases',
  CAPITAL_GOODS = 'CapitalGoods',
  PRODUCT_INPUTS = 'ProductInputs',
}

export enum GET_SUPPLIERS_CATEGORIES {
  INDIRECT_PURCHASES = 'IndirectPurchases',
  CAPITAL_GOODS = 'CapitalGoods',
  PRODUCT_INPUTS = 'ProductInputs',
}

export enum GET_ACCURACY_SCORE {
  VERY_LOW = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  VERY_HIGH = 5,
}

export enum GET_SUPPLIER_PURCHASES_SORT_FIELD {
  ASSIGNED_EMISSION_QUANTITY = 'assignedEmissionQuantity',
  GOODS_SERVICE_NAME = 'goodsServiceName',
}
