import { PRODUCTS_CONTROLLER } from '../controller.common-vars'

export const PRODUCT_IS_PROCESSING_ACTIVITY_DATA_SUBPATH = ':productId/is-processing'
export const PRODUCT_IS_PROCESSING_ACTIVITY_DATA_URI = `${PRODUCTS_CONTROLLER}/${PRODUCT_IS_PROCESSING_ACTIVITY_DATA_SUBPATH}`

export class ProductIsProcessingActivityDataDTOReq {
  startMonth!: string

  productId!: string
}

export type ProductIsProcessingActivityDataDTORes = {
  isProcessing: boolean
}
