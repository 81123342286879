// eslint-disable-next-line max-classes-per-file
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

class FinancialYearSettings {
  @IsBoolean()
  useCalendarYear!: boolean

  @IsString()
  @IsOptional()
  financialYearStartAt?: string
}

class LocationDetails {
  @IsString()
  address!: string

  @IsString()
  city!: string

  @IsString()
  apt!: string

  @IsString()
  countryCode!: string

  @IsString()
  postalCode!: string
}

class OrgSettings {
  @IsString()
  currency!: string

  @Type(() => FinancialYearSettings)
  @ValidateNested()
  financialYearSettings!: FinancialYearSettings

  @Type(() => LocationDetails)
  @ValidateNested()
  locationDetails!: LocationDetails
}

export class SupplierInviteUser {
  @IsString()
  name!: string

  @IsString()
  email!: string

  @IsString()
  @IsOptional()
  password?: string
}

export class SupplierInviteOrganization {
  @IsString()
  companyDisplayName!: string

  @Type(() => OrgSettings)
  @ValidateNested()
  settings!: OrgSettings

  @IsOptional()
  @IsString()
  logo?: string
}

export class AcceptInviteDTO {
  @IsString()
  token!: string

  @Type(() => SupplierInviteUser)
  @ValidateNested()
  user!: SupplierInviteUser

  @Type(() => SupplierInviteOrganization)
  @ValidateNested()
  organization!: SupplierInviteOrganization
}

export type AcceptSupplierInviteDTORes = {
  userId: string
  orgId: string
  session: unknown
}

export const ACCEPT_SUPPLIER_INVITE_SUB_PATH = 'invites/token/:token/accept'
export const ACCEPT_SUPPLIER_INVITE_PATH = `${SUPPLIERS_CONTROLLER}/${ACCEPT_SUPPLIER_INVITE_SUB_PATH}`
