import type { AxiosInstance } from 'axios'

import {
  GET_TOTAL_PRODUCT_QUANTITY_YEARS_URI,
  type TotalProductQuantityYearsRes,
} from '../../DTO/total-product-quantity/total-product-quantity-years.dto'
import {
  GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_URI,
  type TotalProductQuantityDTOReq,
  type TotalProductQuantityDTORes,
} from '../../DTO/total-product-quantity/total-product-quantity-getForOrg.dto'
import { GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_URI } from '../../DTO/total-product-quantity/total-product-quantity-facility-names.dto'

export const useTotalProductQuantityApi = (http: AxiosInstance) => ({
  fetchTotalProductQuantityYears(orgId: string) {
    return http.get<TotalProductQuantityYearsRes>(GET_TOTAL_PRODUCT_QUANTITY_YEARS_URI, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  fetchTotalProductQuantities(payload: TotalProductQuantityDTOReq) {
    return http.get<TotalProductQuantityDTORes>(GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_URI, {
      params: payload,
    })
  },

  getFacilities(orgId: string) {
    return http.get(GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_URI, {
      params: {
        orgId,
      },
    })
  },
})
