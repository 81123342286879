import { IsBoolean } from 'class-validator'

import { SUPPLIER_CORPORATE_REQUESTS_CONTROLLER } from './supplier-corporate-requests.common-vars'

export class SetNoDataSupplierCorporateRequestDto {
  @IsBoolean()
  hasNoData!: boolean
}

export const SET_NO_DATA_CORPORATE_REQUEST_SUB_PATH = ':requestId/no-data'
export const SET_NO_DATA_CORPORATE_REQUEST_PATH = `${SUPPLIER_CORPORATE_REQUESTS_CONTROLLER}/${SET_NO_DATA_CORPORATE_REQUEST_SUB_PATH}`
