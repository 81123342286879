import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

export const ONBOARDING_PROGRESS_CONTROLLER = 'pcf-onboarding'

export const GET_ONBOARDING_PROGRESS_SUBPATH = 'onboarding-progress'
export const GET_ONBOARDING_PROGRESS_URI = `${ONBOARDING_PROGRESS_CONTROLLER}/${GET_ONBOARDING_PROGRESS_SUBPATH}`

export class GetOnboardingProgressDTO extends ValidateDTO {
  @IsUUID()
  rootOrgId!: string
}

export type GetOnboardingProgressDTORes = {
  hasFacilities: boolean
  hasProcessingData: boolean
  hasProducts: boolean
  hasProductStructureFiles: boolean
  hasProductionRecordFiles: boolean
}
