// eslint-disable-next-line max-classes-per-file
import { IsArray, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { DATA_REQUEST_SORT_FIELD, DATA_REQUEST_STATUSES, DATA_REQUEST_TYPES } from '../../../@enums/data-requests.enums'

import { SUPPLIER_DATA_REQUESTS_CONTROLLER } from './supplier-data-requests.common-vars'

export class GetDataRequestsDTO {
  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number

  @IsOptional()
  @IsEnum(DATA_REQUEST_SORT_FIELD)
  sortField?: DATA_REQUEST_SORT_FIELD

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsString()
  @IsOptional()
  query?: string

  @IsOptional()
  @IsArray()
  @IsEnum(DATA_REQUEST_TYPES, { each: true })
  types?: DATA_REQUEST_TYPES[]

  @IsOptional()
  @IsArray()
  @IsEnum(DATA_REQUEST_STATUSES, { each: true })
  statuses?: DATA_REQUEST_STATUSES[]
}

export const GET_SUPPLIER_DATA_REQUESTS_SUB_PATH = ''
export const GET_SUPPLIER_DATA_REQUESTS_PATH = `${SUPPLIER_DATA_REQUESTS_CONTROLLER}/${GET_SUPPLIER_DATA_REQUESTS_SUB_PATH}`
