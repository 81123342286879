// constants related to category Icon and Color

export const SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES: { [key: string]: string | undefined } = {
  'Scope 1': 'kelp',
  'Scope 2': 'sahara',
  'Scope 3': 'noctiluca',
  'Utility Use': 'grass',
  'Waste Generation': 'cornflower',
  'Waste generation': 'cornflower',
  'Use of Products': 'lavender',
  'Direct Gas Release': 'coral',
  Purchases: 'yellow-orange',
  Transportation: 'picton-blue',
  Miscellaneous: 'misc',
  other: 'grey-3',
  'Total Impact': 'kelp',
  'Uk Average': 'grey-3',
  Logistics: 'picton-blue',
  Other: 'grey-3',
  'Electricity Use': 'mac-n-cheese',
  Heating: 'mac-n-cheese',
  'Heating Use': 'mac-n-cheese',
  'Combustion for stationary operations': 'coral',
  'Stationary Combustion for Operations': 'coral',
  'Wastewater generation': 'cornflower',
  'Water Use': 'grass',
  'Post-Consumer Waste': 'cornflower',
  'Fugitive Emissions': 'cornflower',
  'solid waste generation': 'cornflower',
  Waste: 'cornflower',
  Commuting: 'picton-blue',
  'Processing of sold goods / services': 'grey-3',
  'Raw Materials': 'lavender',
  Materials: 'yellow-orange',
  Packaging: 'yellow-orange',
  'Shared Packaging': 'yellow-orange',
  Product: 'lavender',
  Franchises: 'misc',
  'Indirect Purchases': 'yellow-orange',
  'Product & Packaging Inputs': 'yellow-orange',
  'Capital Goods': 'yellow-orange',
}

export const SCOPE_OR_ACTIVITY_AREA_COLOR_HEXES: { [key: string]: string | undefined } = {
  'scope 1': '#005B5E',
  'scope 2': '#FFC67B',
  'scope 3': '#E8B3E8',
  'utility use': '#3DBA60',
  'waste generation': '#6966F2',
  'use of products': '#CA80DC',
  'direct gas release': '#FF7B51',
  purchases: '#FDBF48',
  transportation: '#51C2E6',
  miscellaneous: '#F472B6',
  other: '#B3B3B3',
  'total impact': '#005B5E',
  'uk average': '#B3B3B3',
  logistics: '#51C2E6',
  'electricity use': '#FFB27A',
  heating: '#FFB27A',
  'heating Use': '#FFB27A',
  'combustion for stationary operations': '#FF7B51',
  'stationary combustion for operations': '#FF7B51',
  'wastewater generation': '#6966F2',
  'water use': '#3DBA60',
  'post-consumer waste': '#6966F2',
  'fugitive emissions': '#6966F2',
  'solid waste generation': '#6966F2',
  waste: '#6966F2',
  commuting: '#51C2E6',
  'processing of sold goods / services': '#B3B3B3',
  'raw Materials': '#CA80DC',
  materials: '#FDBF48',
  packaging: '#FDBF48',
  'shared packaging': '#FDBF48',
  product: '#CA80DC',
}

export const CATEGORY_ACTIVITY_ITEMS_ICONS: { [key: string]: string | undefined } = {
  airplane: 'plane',
  'passenger plane': 'plane',
  car: 'car',
  'passenger car': 'car',
  trains: 'train',
  'passenger train': 'train',
  metro: 'subway',
  underground: 'subway',
  bus: 'bus',
  'passenger bus': 'bus',
  motorcycle: 'motorcycle',
  motorbike: 'motorcycle',
  bicycle: 'bicycle',
  electronics: 'desktop',
  catering: 'hamburger',
  furniture: 'chair',
  'office supplies': 'pen-nib',
  commute: 'car',
  'deal related travel': 'usd-circle',
  'client related': 'usd-circle',
  'travel internally': 'suitcase',
  internal: 'suitcase',

  // Scope 1 cats
  'stationary combustion': 'thermometer-full',
  'mobile combustion': 'gas-pump',
  'direct & fugitive emissions': 'smoke',

  // Scope 2 cats
  'electricity use (inc. for heating & cooling)': 'bolt',
  'steam use': 'heat',
  'direct heat use': 'burn',
  'direct cooling use': 'dumpster',

  // Scope 3 cats
  'purchased goods & services': 'shopping-cart',
  'capital goods': 'cogs',
  'other fuel & energy related activities': 'plug',
  'upstream transportation & distribution': 'truck-container',
  'waste generated in operations': 'trash-alt',
  'business travel': 'suitcase',
  travel: 'suitcase',
  'employee commuting': 'train',
  'upstream leased assets': 'forklift',
  'downstream transportation & distribution': 'shipping-fast',
  'processing of sold products': 'pallet-alt',
  'use of sold products': 'person-carry',
  'end of life treatment of sold products': 'dumpster',
  'downstream leased assets': 'truck-loading',
  franchises: 'sitemap',
  investments: 'coins',

  // Activities
  'electricity use': 'bolt',
  heating: 'burn',
  'heating use': 'burn',
  water: 'tint',
  'water use': 'tint',
  'combustion for stationary operations': 'fire',
  'stationary combustion for operations': 'fire',
  'solid waste generation': 'trash-alt',
  'wastewater generation': 'water',
  'post-consumer waste': 'dumpster',
  'fugitive emissions': 'smoke',
  'direct emissions from operations': 'clouds',
  logistics: 'shipping-fast',
  'business transport': 'suitcase',
  commuting: 'train',
  'purchased materials, goods or services': 'shopping-cart',
  'purchased materials, goods & services': 'shopping-cart',
  'processing of sold goods / services': 'boxes',
  'processing of sold goods & services': 'boxes',
  'consumer use of sold goods / services': 'person-carry',
  'use of sold goods & services': 'person-carry',
  'leased capital goods use': 'cogs',
  'use of leased capital goods': 'cogs',
  'uk average': 'chart-bar',
  'release of direct emissions from operations': 'smoke',
  'release of fugitive emissions': 'smoke',
  'indirect purchases': 'shopping-basket',
  'product & packaging inputs': 'box-alt',
  packaging: 'box-alt',
  'shared packaging': 'box-alt',
  'raw materials': 'pie-chart',
  Materials: 'pie-chart',
}

export const INTENSITY_METRIC_ICONS: { [key: string]: string | undefined } = {
  'per $ revenue': 'sack-dollar',
  'per employee': 'users',
  'per unique customer': 'head-side',
  'per facility': 'building',
  'per order': 'shopping-cart',
}
