export enum TAG_TYPES {
  ENERGY_GENERATION_TYPE = 'energy_generation_type',
  GAS_TYPE = 'gas_type',
  GOODS_SERVICE_TYPE = 'goods_service_type',
  INDUSTRY_TYPE = 'industry_type',
  ISIC_CLASS = 'isic_class',
  ISIC_DIVISION = 'isic_division',
  ISIC_GROUP = 'isic_group',
  ISIC_SECTION = 'isic_section',
  MATERIAL_LIFE_PHASE = 'material_life_phase',
  REFRIGERANT_TYPE = 'refrigerant_type',
  RENEWABLE = 'renewable',
  TAG_COMBINATION = 'tag_combination',
  VEHICLE_LOAD = 'vehicle_load',
  VEHICLE_TYPE = 'vehicle_type',
  WASTE_CATEGORY = 'waste_category',
  WASTE_TREATMENT_TYPE = 'waste_treatment_type',
  WASTE_TYPE = 'waste_type',
  WASTEWATER_SPEND_TYPE = 'wastewater_spend_type',
  WATER_SPEND_TYPE = 'water_spend_type',
}

export enum TEMPORARY_HARDCODED_TAG_TYPES {
  ENERGY_GENERATION_TYPE = 'energy_generation_type',
  TRANSPORT_FUEL_TYPE = 'transport_fuel_type',
  HEATING_FUEL_TYPE = 'heating_fuel_type',
  STATIONARY_COMBUSTION_FUEL_TYPE = 'stationary_combustion_fuel_type',
  GAS_TYPE = 'gas_type',
  GOODS_SERVICE_TYPE = 'goods_service_type',
  INDUSTRY_TYPE = 'industry_type',
  ISIC_CLASS = 'isic_class',
  ISIC_DIVISION = 'isic_division',
  ISIC_GROUP = 'isic_group',
  ISIC_SECTION = 'isic_section',
  MATERIAL_LIFE_PHASE = 'material_life_phase',
  REFRIGERANT_TYPE = 'refrigerant_type',
  DIRECT_EMISSIONS = 'direct_emissions',
  RENEWABLE = 'renewable',
  TAG_COMBINATION = 'tag_combination',
  VEHICLE_LOAD = 'vehicle_load',
  VEHICLE_TYPE = 'vehicle_type',
  WASTE_CATEGORY = 'waste_category',
  WASTE_TREATMENT_TYPE = 'waste_treatment_type',
  WASTE_TYPE = 'waste_type',
  WASTEWATER_SPEND_TYPE = 'wastewater_spend_type',
  WATER_SPEND_TYPE = 'water_spend_type',
}
